import { createContext } from "react";
import { ITransactionProductContext, ITransactionProductState } from "./state";

export const initialTransactionProductState: ITransactionProductState = {
  showFilter: false,
  chip: [],
};

const TransactionProductContext = createContext<ITransactionProductContext>({
  TransactionProductState: initialTransactionProductState,
  TransactionProductDispatch: () => undefined,
});

export const TransactionProductContextProvider = TransactionProductContext.Provider;
export const TransactionProductContextConsumer = TransactionProductContext.Consumer;

export default TransactionProductContext;
