import { IPagination, ISort } from "../../core/helpers/helper";
import {
  ERetailShopSortField,
  PRetailShopFilter,
} from "../../core/retail_shop/entities";
import RetailShopRepository from "../../core/retail_shop/repository/repository";
import RetailShopUsecase from "../../core/retail_shop/usecase/usecase";

interface IRetailShopService {}

export class RetailShopService implements IRetailShopService {
  static getRetailShop = async (
    pagination: IPagination,
    filter: PRetailShopFilter,
    sorts: ISort<ERetailShopSortField>[]
  ) => {
    try {
      const shopRepo = new RetailShopRepository();
      const shopUsecase = new RetailShopUsecase(shopRepo);
      const shop = await shopUsecase.getRetailShop(pagination, filter, sorts);
      return shop;
    } catch (error) {
      console.log(error);
    }
  };
}
