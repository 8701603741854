import { IBuiltinSort, IPagination } from "../../core/helpers/helper";
import {
  ERetailRevenueSortField,
  PRetailRevenueFilter,
} from "../../core/retail_revenue/entities";
import RetailRevenueRepository from "../../core/retail_revenue/repository/repository";
import RetailRevenueUsecase from "../../core/retail_revenue/usecase/usecase";

export class RetailRevenueService {
  static getRetailRevenues = async (
    pagination: IPagination,
    filter: PRetailRevenueFilter,
    sorts: IBuiltinSort<ERetailRevenueSortField>[]
  ) => {
    try {
      const retailRevenueRepo = new RetailRevenueRepository();
      const retailRevenueUsecase = new RetailRevenueUsecase(retailRevenueRepo);
      const RetailRevenue = await retailRevenueUsecase.getRetailRevenues(
        pagination,
        filter,
        sorts
      );
      return RetailRevenue;
    } catch (error) {
      console.log(error);
    }
  };

  static getRetailRevenue = async (code: string) => {
    try {
      const retailRevenueRepo =
        new RetailRevenueRepository();
      const retailRevenueUsecase = new RetailRevenueUsecase(
        retailRevenueRepo
      );
      const retailRevenue =
        await retailRevenueUsecase.getRetailRevenue(code);
      return retailRevenue;
    } catch (error) {
      console.log(error);
    }
  };
}
