import moment from "moment";
import React, { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { TicketRequest } from "../../../apis/ticket/TicketRequest";
import Breadcrumb from "../../../components/atoms/breadcrumb/Breadcrumb";
import Input from "../../../components/atoms/input/Input";
import CircularLoad from "../../../components/atoms/loading/CircularLoad";
import Loading from "../../../components/atoms/loading/Loading";
import Toggle from "../../../components/atoms/button/Toggle";
import RejectTicketModal from "../../../components/organisms/ticket/RejectTicketModal";
import RevisionTicketModal from "../../../components/organisms/ticket/RevisionTicketModal";
import { ActionType } from "../../../store/ticket/action";
import TicketContext from "../../../store/ticket/context";

const breadcrumbRoute = (id?: string | undefined) => {
  return [
    {
      id: 1,
      path: "/home",
      name: "Home",
    },
    {
      id: 2,
      path: "/ticket",
      name: "Ticket",
    },
    {
      id: 3,
      path: `/ticket/${id}`,
      name: "Detail",
    },
  ];
};

const TicketDetail = () => {
  const { TicketDispatch, TicketState } = useContext(TicketContext);
  const { showRejectTicket, showRevisiTicket } = TicketState;
  const params = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    ["getTicketDetail"],
    () => TicketRequest.getTicketDetail(params.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleAcceptTicket = () => {
    const id = params.id;
    mutation.mutate(id);
  };

  const mutation = useMutation(
    (id: string | undefined) => TicketRequest.acceptTicket(id),
    {
      onMutate: () => {
        console.log("mutate accept ticket");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        }
      },
    }
  );

  const handleRejectTicket = () => {
    const id = Number(params.id);
    TicketDispatch({
      type: ActionType.ShowRejectTicket,
      payload: {
        id: id,
        status: true,
      },
    });
  };

  const handleRevisionTicket = () => {
    const id = Number(params.id);
    TicketDispatch({
      type: ActionType.ShowRevisiTicket,
      payload: {
        id: id,
        status: true,
      },
    });
  };

  return (
    <div className="font-med">
      {showRejectTicket.status && <RejectTicketModal />}
      {showRevisiTicket.status && <RevisionTicketModal />}
      <Breadcrumb data={breadcrumbRoute(params.id)} />
      {isLoading ? (
        <div className="flex justify-center mt-20">
          <Loading />
        </div>
      ) : (
        <section>
          <div className="w-full h-full md:my-10 my-8 md:flex justify-between">
            <div className="md:w-1/3">
              <div className="w-full rounded-md border-2 border-stroke">
                <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5 bg">
                  <h1 className="font-medium md:text-xl text-sm">
                    Product Images
                  </h1>
                </div>
                <hr />

                <div className="md:m-7 m-5">
                  <div>
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Display Image
                    </h1>
                    <div className="flex justify-center items-center">
                      <img
                        src={data?.preview_image_path}
                        alt="-"
                        className="w-1/3 rounded-md shadow-md"
                      />
                    </div>
                  </div>
                  {data.display_media_type === "image" ? (
                    <div className="mt-6 text-textPrimary">
                      <h1 className="md:text-base text-sm">Upload Images</h1>
                      <div className="flex justify-center items-center">
                        <img
                          src={data?.display_image_path}
                          alt="-"
                          className="w-2/5 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mt-6 text-textPrimary">
                      <div className="flex justify-center">
                        <video
                          controls
                          src={data?.display_video_path}
                          autoPlay={true}
                          className="w-2/5 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full my-10 rounded-md border-2 border-stroke">
                <div className="items-center md:m-7 m-5">
                  <h1 className="md:text-base text-sm">Detail Images</h1>
                  <h1 className="text-xs font-rom text-textPrimary mt-1">
                    Note: You can add your size chart or detail type of your
                    product
                  </h1>
                </div>
                <hr />
                <div className="md:m-7 m-5">
                  <div className="grid grid-cols-4 gap-3">
                    {data?.description_image_paths?.map((el: any) => (
                      <img
                        src={el?.image_path}
                        alt="-"
                        className="w-full rounded-md shadow-md"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="md:w-custom md:mt-0 mt-10 w-full h-full">
              <div className="rounded-md rounded-b-none border-2 border-stroke ">
                <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
                  <h1 className="font-medium md:text-xl text-sm">
                    Product Information
                  </h1>
                  <div className="flex">
                    <h1 className="font-medium md:text-base text-sm mr-3 text-textPrimary">
                      Hide Product
                    </h1>
                    <Toggle
                      disabled={true}
                      input={data.is_active}
                      handleClick={() => {}}
                    />
                  </div>
                </div>
                <hr />
                <div className="md:mx-8 mx-5 md:my-5 my-3">
                  <div>
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Product Name
                    </h1>
                    <Input
                      type={"text"}
                      disable={true}
                      value={data.name}
                      name={"name"}
                      placeholder={"Place holder"}
                    />
                  </div>
                </div>

                <div className="md:flex w-full">
                  {/*section product left*/}
                  <div className="md:mx-8 mx-5 md:w-1/2">
                    <div className="md:mt-0">
                      <h1 className="md:text-base text-sm  text-textPrimary">
                        Release Date
                      </h1>
                      <Input
                        disable={true}
                        value={moment(data.release_date).format(
                          "YYYY-MM-DDTHH:mm"
                        )}
                        type={"datetime-local"}
                        name={"release_date"}
                        placeholder={"Place holder"}
                      />
                    </div>
                  </div>

                  {/*section product right*/}
                  <div className="md:mx-8 mx-5 md:w-1/2"></div>
                </div>
                <div className="md:my-4 my-5 md:mx-8 mx-5">
                  <h1 className="md:text-base text-sm  text-textPrimary">
                    Description
                  </h1>
                  <textarea
                    disabled={true}
                    value={data.description}
                    name="description"
                    placeholder="Place holder"
                    className="md:h-28 h-24 font-rom w-full rounded-md md:text-base text-xs my-3 px-3 py-3 border-2 border-stroke focus:ring-1 focus:outline-none"
                  ></textarea>
                </div>
                {data?.section_details.length >= 1 && (
                  <section className="md:mx-8 mx-5 md:mb-8 mb-5">
                    <div className="flex justify-between">
                      <h1 className="text-xl">Section 1</h1>
                      <div className="flex items-center">
                        <h1 className="font-medium md:text-base text-sm mr-3 text-textPrimary">
                          Active
                        </h1>
                        <Toggle
                          input={data.section_details[0].is_active}
                          handleClick={() => {}}
                        />
                      </div>
                    </div>
                    <hr className="my-1" />
                    <div className="w-full md:flex mt-4">
                      <div className="w-1/2">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          upload Image
                        </h1>
                        {data?.section_details.length >= 1 && (
                          <div className="flex justify-center items-center">
                            <img
                              src={data?.section_details[0].image_path}
                              alt="-"
                              className="w-1/2 rounded-md shadow-md my-1"
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-1/2 md:ml-8">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Description
                        </h1>
                        <textarea
                          disabled={true}
                          value={data.section_details[0].description || "-"}
                          name="description_section_1"
                          placeholder="Place holder"
                          className="h-24 font-rom w-full rounded-md md:text-base text-xs mt-5 px-3 py-3 border-2 border-stroke focus:ring-1 focus:outline-none"
                        ></textarea>
                      </div>
                    </div>
                  </section>
                )}

                {data?.section_details.length >= 2 && (
                  <section className="md:mx-8 mx-5 md:mb-8 mb-5">
                    <div className="flex justify-between">
                      <h1 className="text-xl">Section 2</h1>
                      <div className="flex items-center">
                        <h1 className="font-medium md:text-base text-sm mr-3 text-textPrimary">
                          Active
                        </h1>
                        <Toggle
                          input={data.section_details[1].is_active}
                          handleClick={() => {}}
                        />
                      </div>
                    </div>
                    <hr className="my-1" />
                    <div className="w-full md:flex mt-4">
                      <div className="w-1/2">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          upload Image
                        </h1>
                        {data?.section_details.length >= 2 && (
                          <div className="flex justify-center items-center">
                            <img
                              src={data?.section_details[1].image_path}
                              alt="-"
                              className="w-1/2 rounded-md shadow-md my-1"
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-1/2 md:ml-8">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Description
                        </h1>
                        <textarea
                          value={data.section_details[1].description || "-"}
                          disabled={true}
                          name="description_section_2"
                          placeholder="Place holder"
                          className="h-24 font-rom w-full rounded-md md:text-base text-xs mt-5 px-3 py-3 border-2 border-stroke focus:ring-1 focus:outline-none"
                        ></textarea>
                      </div>
                    </div>
                  </section>
                )}
              </div>

              <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-20 h-14 mb-10 z-40">
                <div className="w-1/3 flex items-center justify-center border-r-2 border-stroke ">
                  <button
                    type="button"
                    onClick={handleRejectTicket}
                    className="focus:outline-none"
                  >
                    <h1 className="md:text-lg text-sm cursor-pointer hover:text-primary">
                      Reject
                    </h1>
                  </button>
                </div>
                <div className="w-1/3 flex items-center justify-center border-r-2 border-stroke ">
                  <button
                    type="button"
                    onClick={handleRevisionTicket}
                    className="focus:outline-none"
                  >
                    <h1 className="md:text-lg text-sm cursor-pointer hover:text-primary">
                      Revision
                    </h1>
                  </button>
                </div>
                <div className="w-1/3 flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleAcceptTicket}
                    className="focus:outline-none cursor-pointer"
                  >
                    {mutation.isLoading ? (
                      <div className="flex justify-center">
                        <CircularLoad color={"black"} height={17} width={17} />
                      </div>
                    ) : (
                      <h1 className="md:text-lg text-sm cursor-pointer hover:text-primary">
                        Accept
                      </h1>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default TicketDetail;
