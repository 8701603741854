import React from "react";
import { Asearch } from "./icons";

interface IAsearchInput {
    setSearch: (value: string) => void;
    search: string;
    handleSearch: () => void
}

const AsearchInput:React.FC<IAsearchInput> = ({search, setSearch, handleSearch}) => {
  return (
    <>
      <input
        type="text"
        placeholder="Search Here..."
        className="h-[2.4rem] w-full border border-stroke rounded-md rounded-r-none p-2 text-textPrimary sm:text-xs text-xxs font-rom focus:border-2 focus:border-[#2684ff] focus:outline-none"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      <i
        onClick={handleSearch}
        className="bg-white border border-stroke border-l-0 rounded-r-md p-2.5 cursor-pointer hover:bg-opacity-50 hover:bg-background"
      >
        <Asearch width="16" height="16" />
      </i>
    </>
  );
};

export default AsearchInput;
