import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSpring, animated } from "react-spring";
import { showPass, unshowPass } from "../../assets/icons/Web";
import { logo } from "../../assets/icons";
import CircularLoad from "../../components/atoms/loading/CircularLoad";
import Backdrop from "./login/Backdrop";
import Input from "../../components/atoms/input/Input";
import LocalStorage from "../../config/LocalStorage";
import Snackbar from "../../components/atoms/toast/Snackbar";
import useAuth from "../../helpers/useAuth";
import { storage } from "../../utils/Storage";
import { AuthService } from "../../apis/auth";
import jwtDecode from "jwt-decode";

function LoginPage() {
  const navigate = useNavigate();
  const [passType, setPassType] = useState<string>("password");
  const { setAuth } = useAuth();
  const props = useSpring({
    to: { opacity: 1, marginTop: 0 },
    from: { opacity: 0, marginTop: -1000 },
  });

  const { handleSubmit, handleChange, errors, setErrors, touched, values } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: Yup.object({
        username: Yup.string().required("Required"),
        password: Yup.string()
          .required("Required!")
          .min(6, "The password must be of minimum 6 characters!"),
      }),
      onSubmit: (values) => {
        mutation.mutate(values);
      },
    });

  const mutation = useMutation((values: any) => AuthService.login(values), {
    onMutate: () => {
      console.log("mutate login");
    },
    onSettled: async (data: any) => {
      if (data.errors) {
        setErrors({ password: data.errors });
      } else {
        document.body.classList.remove("h-screen", "overflow-hidden");
        const token = data.token;
        const decoded: { role: string; username: string } = jwtDecode(token);
        storage.onConnect().then(function () {
          return storage.set("AUTH_TOKEN", token);
        });
        storage.onConnect().then(function () {
          return storage.set("ROLE", decoded.role);
        });
        LocalStorage.AUTH_TOKEN = token;
        LocalStorage.ROLE = decoded.role;
        LocalStorage.USERNAME = decoded.username;
        setAuth({
          role: decoded.role,
          token: token,
          username: decoded.username
        });
        navigate("/home");
        Snackbar("#00C62E", "Login successfully!");
      }
    },
  });

  const handleVisibleType = () => {
    if (passType === "password") {
      setPassType("text");
    } else {
      setPassType("password");
    }
  };

  return (
    <>
      <Backdrop active={true} />
      <animated.div
        style={props}
        className="fixed inset-0 z-50 w-full h-screen flex items-center justify-center "
      >
        <div className="flex flex-col bg-white rounded-md md:w-Wlogin md:h-Hlogin w-4/5">
          <form onSubmit={handleSubmit}>
            <div className="md:mt-7 mt-8 md:mb-8 mb-8 flex flex-col justify-center items-center">
              <img alt="" src={logo} className="md:w-12 w-10" />
              <div className="flex md:mt-4 mt-3">
                <h1 className="text-primary md:text-xl text-lg">DEPATU</h1>
                <h1 className="md:text-xl text-lg ml-2">Official Store</h1>
              </div>
            </div>

            <div className="md:mx-14 mx-8 mb-5">
              <div className="">
                <h1 className="md:text-sm text-xs text-textPrimary">
                  Username
                </h1>
                <Input
                  type={"text"}
                  placeholder={"username"}
                  value={values.username}
                  name={"username"}
                  handleChange={handleChange}
                />
                {touched.username && errors.username ? (
                  <h1 className="text-xs font-rom text-primary -mt-2 mb-2">
                    {errors.username}
                  </h1>
                ) : null}
              </div>
              <div className="">
                <h1 className="md:text-sm text-xs text-textPrimary">
                  Password
                </h1>
                <div className="md:h-10 h-8 rounded-md border-2 my-2 border-stroke flex justify-between">
                  <input
                    type={passType}
                    placeholder="password"
                    className="md:text-sm font-rom text-xs px-3 w-full mr-5 focus:outline-none"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {passType === "password" ? (
                    <img
                      src={showPass}
                      onClick={handleVisibleType}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt=""
                    />
                  ) : (
                    <img
                      src={unshowPass}
                      onClick={handleVisibleType}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt=""
                    />
                  )}
                </div>
                {touched.password && errors.password ? (
                  <h1 className="text-xs font-rom text-primary -mt-1 mb-1">
                    {errors.password}
                  </h1>
                ) : null}
              </div>
            </div>
            <div
              className={
                "flex justify-center md:mb-0 mb-5 " +
                (errors.password && errors.username ? "-mt-2" : "mt-6")
              }
            >
              <button
                disabled={mutation.isLoading ? true : false}
                type="submit"
                className="rounded-full md:w-40 w-28 md:h-10 h-6 md:pb-0 pb-0.5 bg-primary focus:outline-none hover hover:bg-opacity-50"
              >
                {mutation.isLoading ? (
                  <div className="flex justify-center">
                    <CircularLoad color={"white"} height={17} width={17} />
                  </div>
                ) : (
                  <h1 className="text-white md:text-base text-sm">Login</h1>
                )}
              </button>
            </div>
          </form>
        </div>
      </animated.div>
    </>
  );
}

export default LoginPage;
