import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Aproduct: FC<IIconProps> = ({
  stroke = "#666666",
  width = "26",
  height = "29",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 20.5181V8.19312C24.999 8.01509 24.9512 7.84046 24.8612 7.68684C24.7712 7.53322 24.6423 7.40604 24.4875 7.31812L13.4875 1.13063C13.3393 1.04505 13.1711 1 13 1C12.8289 1 12.6607 1.04505 12.5125 1.13063L1.5125 7.31812C1.35769 7.40604 1.22879 7.53322 1.13882 7.68684C1.04884 7.84046 1.00096 8.01509 1 8.19312V20.5181C1.00096 20.6962 1.04884 20.8708 1.13882 21.0244C1.22879 21.178 1.35769 21.3052 1.5125 21.3931L12.5125 27.5806C12.6607 27.6662 12.8289 27.7113 13 27.7113C13.1711 27.7113 13.3393 27.6662 13.4875 27.5806L24.4875 21.3931C24.6423 21.3052 24.7712 21.178 24.8612 21.0244C24.9512 20.8708 24.999 20.6962 25 20.5181V20.5181Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8624 7.68066L13.1125 14.3557L1.13745 7.68066"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1125 14.3555L13 27.7055"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
