import { FC } from "react";

export interface IIconProps {
  stroke?: string;
  width?: string;
  height?: string;
}

export const Ahome: FC<IIconProps> = ({
  stroke = "#666666",
  width = "24",
  height = "25",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 22.9998V16.9998C15 16.7346 14.8946 16.4802 14.7071 16.2927C14.5196 16.1051 14.2652 15.9998 14 15.9998H10C9.73478 15.9998 9.48043 16.1051 9.29289 16.2927C9.10536 16.4802 9 16.7346 9 16.9998V22.9998C9 23.265 8.89464 23.5193 8.70711 23.7069C8.51957 23.8944 8.26522 23.9998 8 23.9998H2C1.73478 23.9998 1.48043 23.8944 1.29289 23.7069C1.10536 23.5193 1 23.265 1 22.9998V11.4373C1.00224 11.2989 1.03215 11.1623 1.08796 11.0357C1.14378 10.909 1.22437 10.7948 1.325 10.6998L11.325 1.61228C11.5093 1.44363 11.7501 1.3501 12 1.3501C12.2499 1.3501 12.4907 1.44363 12.675 1.61228L22.675 10.6998C22.7756 10.7948 22.8562 10.909 22.912 11.0357C22.9679 11.1623 22.9978 11.2989 23 11.4373V22.9998C23 23.265 22.8946 23.5193 22.7071 23.7069C22.5196 23.8944 22.2652 23.9998 22 23.9998H16C15.7348 23.9998 15.4804 23.8944 15.2929 23.7069C15.1054 23.5193 15 23.265 15 22.9998Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
