import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { IRetailTransactionCounter, listFilter } from "../../core/retail_transaction/entities";
import { useQueries } from "../../helpers/QuerySearchParams";

interface IMfilterTransactionStatusProps {
  item: { title: string; status: number[] };
  counter: IRetailTransactionCounter[];
}

const MfilterTransactionStatus: React.FC<IMfilterTransactionStatusProps> = ({
  item,
  counter,
}) => {
  const query = useQueries(useLocation);
  const { search } = useLocation();
  const navigate = useNavigate();
  const prevStatus = query.get("status");
  const offset = query.get("offset");
  const code = query.get("code");
  let newSearch = search.replace(`offset=${offset}`, `offset=0`).replace(`&&code=${code}`, '');

  const handleChangeStatus = () => {
    if (prevStatus) {
      newSearch = newSearch.replace(
        `status=${prevStatus.split(" ").join("%20")}`,
        `status=${item.status.join(",")}`
      );
    } else {
      newSearch = `${newSearch}&&status=${item.status.join(",")}`;
    }
    navigate({ search: newSearch });
  };

  const status = query
    .get("status")
    ?.split(",")
    ?.map((el) => +el) || listFilter[0].status;

  return (
    <div
      className={
        "flex justify-center sm:py-3 py-2 sm:px-0 px-3 border-b-2 w-full sm:rounded-none rounded-full whitespace-nowrap duration-500 sm:font-med font-rom sm:mx-0 mx-1 " +
        (_.isEqual(status, item.status)
          ? "sm:border-b-primary sm:bg-inherit bg-primary sm:text-inherit text-w"
          : "border-background sm:bg-inherit bg-white")
      }
    >
      <div
        onClick={handleChangeStatus}
        className={
          "flex items-center gap-1 hover:text-primary cursor-pointer sm:text-sm text-xs px-3 " +
          (_.isEqual(status, item.status)
            ? "sm:text-primary text-white"
            : "text-textSecondary")
        }
      >
        <h1>{item.title}</h1>
        <h1>
          {`(${counter
            .map((el) => item.status.includes(el.status) && el.count)
            .filter((el) => el)
            .reduce((prev: any, current: any) => prev + current, 0)})`}
        </h1>
      </div>
    </div>
  );
};

export default MfilterTransactionStatus;
