import { IMeta, IPagination, ISort } from "../../helpers/helper";
import {
  ERetailShopSortField,
  IRetailShop,
  PRetailShopFilter,
} from "../entities";
import IRetailShopRepository from "../repository";
import IRetailShopUsecase from "../usecase";

export default class RetailShopUsecase implements IRetailShopUsecase {
  private retailShopRepo: IRetailShopRepository;

  constructor(retailShopRepo: IRetailShopRepository) {
    this.retailShopRepo = retailShopRepo;
  }

  async getRetailShop(
    pagination: IPagination,
    filter: PRetailShopFilter,
    sorts: ISort<ERetailShopSortField>[]
  ): Promise<[IRetailShop[], IMeta]> {
    const [data, meta] = await this.retailShopRepo.getRetailShop(
      pagination,
      filter,
      sorts
    );
    return [data, meta];
  }

  //   async getRetailShopById(id: number): Promise<IRetailShop> {
  //     const retailShop = await this.retailShopRepo.getRetailShopById(id);
  //     return retailShop;
  //   }
}
