import React, { useContext, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import moment from "moment";

import Breadcrumb from "../../components/atoms/breadcrumb/Breadcrumb";
import CircularLoad from "../../components/atoms/loading/CircularLoad";
import Loading from "../../components/atoms/loading/Loading";
import Sorting from "../../components/atoms/sorting/Sorting";
import RetailShopTable from "../../components/organisms/retail_shop/RetailShopTable";
import { RetailShopSortField } from "../../core/retail_shop/entities";
import { ActionType } from "../../store/retail_shop/action";
import RetailShopContext from "../../store/retail_shop/context";
import { UseOutsideClick } from "../../helpers/useOutsideClick";
import { useFetchRetailShops } from "../../hooks/retail_shop";

export const retailShopRoute = (id?: string) => {
  return [
    {
      id: 1,
      path: "/dashboard",
      name: "Home",
    },
    {
      id: 2,
      path: "/retail-shop",
      name: "Stores",
    },
  ];
};

const RetailShop = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { RetailShopDispatch, RetailShopState } = useContext(RetailShopContext);
  const { showFilter } = RetailShopState;
  const { data, isLoading, isFetching } = useFetchRetailShops();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 30),
      key: "selection",
    },
  ]);

  const handleShowFilter = () => {
    RetailShopDispatch({
      type: ActionType.ShowFilter,
    });
  };

  const filterRef = useRef(null);
  UseOutsideClick(filterRef, handleShowFilter);

  return (
    <main className="bg-white">
      <section id="top">
        <Breadcrumb data={retailShopRoute()} />
        {/* <RetailShopFilter /> */}
        <div className="md:mt-12 mt-10">
          <div className="flex justify-end">
            <h1
              onClick={() => navigate(`${pathname}/add-retail-shop`)}
              className="hover:text-primary md:text-base cursor-pointer inline-flex ml-5"
            >
              Add Store
            </h1>
          </div>
          <div className="flex justify-between my-2.5">
            <div className="flex">
              <h1
                onClick={handleShowFilter}
                className="text-base mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
            </div>
            <Sorting option={RetailShopSortField} />
          </div>
          <div className="flex items-center">
            <div
              onClick={handleShowFilter}
              className="font-rom w-52 h-10 rounded-md shadow-md flex justify-center items-center cursor-pointer hover:bg-gray-200"
            >
              <h1>
                {moment(state[0].startDate).format("DD/MM/YYYY") ===
                moment(state[0].endDate).format("DD/MM/YYYY")
                  ? `${moment(state[0].startDate).format("DD/MM/YYYY")}`
                  : `${moment(state[0].startDate).format(
                      "DD/MM/YYYY"
                    )} - ${moment(state[0].endDate).format("DD/MM/YYYY")}`}
              </h1>
            </div>
            <div
              onClick={() =>
                setState([
                  {
                    startDate: new Date(),
                    endDate: addDays(new Date(), 1),
                    key: "selection",
                  },
                ])
              }
              className="ml-5"
            >
              <h1 className="cursor-pointer hover:text-primary">Reset</h1>
            </div>
          </div>
          {showFilter && (
            <div ref={filterRef} className="fixed z-50 my-5">
              <DateRangePicker
                className="shadow-md"
                onChange={(item: any) => setState([item.selection])}
                showMonthAndYearPickers={true}
                color="#ED4846"
                rangeColors={["#ED4846"]}
                retainEndDateOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="vertical"
              />
            </div>
          )}

          {/* <Chip
              dispatchContext={CreditEffectDispatch}
              stateContext={CreditEffectState}
              queryParams={queryparamsCreditEffect}
              ActionType={ActionType}
            /> */}
        </div>
        <div className="flex justify-end my-4">
          {isFetching ? (
            <div className="flex items-center">
              <CircularLoad color="#DF5354" height={20} width={20} />
              <h1 className="ml-2">Updating</h1>
            </div>
          ) : (
            <h1>Auto Update</h1>
          )}
        </div>
      </section>
      <section>
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div className="w-full">
            <RetailShopTable data={data} />
          </div>
        )}
      </section>
    </main>
  );
};

export default RetailShop;
