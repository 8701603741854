import { isEqual } from "lodash";
import { formatDate } from "../../helpers/common";
interface IAfilterDateInputProps {
  showFilter: boolean;
  handleShowFilter: () => void;
  date: {
    startDate: Date | string;
    endDate: Date | string;
    key: string;
  }[];
  handleReset: () => void;
  applyFilter: () => void;
}

const AfilterDateInput: React.FC<IAfilterDateInputProps> = ({
  handleShowFilter,
  date,
  showFilter,
  handleReset,
  applyFilter,
}) => {
  return (
    <div className="flex items-center">
      <div
        onClick={handleShowFilter}
        className="bg-white font-rom text-sm w-48 h-9 rounded-md rounded-r-none flex justify-center items-center cursor-pointer border-stroke border"
      >
        <h1 className="text-xs">
          {isEqual(
            formatDate(date[0].startDate, "DD/MM/YYYY"),
            formatDate(date[0].endDate, "DD/MM/YYYY")
          )
            ? formatDate(date[0].startDate, "DD/MM/YYYY")
            : `${formatDate(date[0].startDate, "DD/MM/YYYY")} - ${formatDate(
                date[0].endDate,
                "DD/MM/YYYY"
              )}`}
        </h1>
      </div>
      <div
        onClick={showFilter ? applyFilter : handleReset}
        className="bg-white h-9 border-stroke rounded-r-md border border-l-0 px-2.5 flex items-center cursor-pointer"
      >
        {showFilter ? (
          <h1 className="hover:text-primary text-xs text-textPrimary">
            Apply
          </h1>
        ) : (
          <h1 className="hover:text-primary text-xs text-textPrimary">
            Reset
          </h1>
        )}
      </div>
    </div>
  );
};

export default AfilterDateInput;
