import React from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router";
import ReactTooltip from "react-tooltip";
import { TicketRequest } from "../../apis/ticket/TicketRequest";
import { list } from "../../assets/icons";
import Breadcrumb from "../../components/atoms/breadcrumb/Breadcrumb";
import Loading from "../../components/atoms/loading/Loading";
import Table from "../../components/organisms/Table";
import { formatRupiah } from "../../helpers/common";
import { useQueries } from "../../helpers/QuerySearchParams";

const breadcrumbRoute = (id?: string) => {
  return [
    {
      id: 1,
      path: "/home",
      name: "Home",
    },
    {
      id: 2,
      path: "/ticket",
      name: "Ticket",
    },
    {
      id: 3,
      path: `/ticket/${id}/stock-ticket`,
      name: "Stock Ticket",
    },
  ];
};

const StockTicket = () => {
  const query = useQueries(useLocation);
  const params = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    [
      "ticketVariant",
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
    ],
    () => TicketRequest.getTicketVariant(params.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="">
      <Breadcrumb data={breadcrumbRoute(params.id)} />
      {isLoading ? (
        <div className="flex justify-center mt-20">
          <Loading />
        </div>
      ) : (
        <div>
          <div className="md:my-14 my-10 flex flex-col items-center">
            <div className="flex flex-col items-center">
              <h1 className="md:text-3xl text-lg">
                {data?.data?.data?.[0]?.ticket_bundle_name}
              </h1>
            </div>

            <div className="md:hidden flex justify-center bg-red-300 fixed bottom-20 w-full">
              <div className="flex rounded-full shadow-lg w-2/5 h-10 justify-center bg-white fixed items-end">
                <div className="h-full flex justify-center items-center"></div>
              </div>
            </div>
          </div>
          <div className="md:inline hidden">
            <section className="my-14">
              <Table
                isTable={true}
                data={data?.data}
                tableHead={[
                  "Variant",
                  "Price",
                  "Stock",
                  "Ticket Sold",
                  "Actions",
                ]}
              >
                {data?.data?.data?.map((el: any) => (
                  <tr
                    key={el.id}
                    className="border h-24 font-rom hover:bg-gray-50"
                  >
                    <td className="border-r">{el.label}</td>
                    <td className="border-r">{formatRupiah(el.price)}</td>
                    <td className="border-r">{el.stock}</td>
                    <td className="border-r">{el.sold_count}</td>
                    <td className="">
                      <div className="flex justify-evenly">
                        <div>
                          <img
                            data-tip
                            data-for="variant"
                            className="cursor-pointer md:w-5"
                            onClick={() =>
                              navigate(
                                `/ticket/${params.id}/stock-ticket/${el.id}`
                              )
                            }
                            src={list}
                            alt="variant"
                          />
                          <ReactTooltip id="variant" type="info">
                            <span>Variant</span>
                          </ReactTooltip>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </Table>
            </section>
          </div>
          {/* <div className="md:hidden flex">
            <ProductVariantList data={data} />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default StockTicket;
