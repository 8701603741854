import { useReducer } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import {
  initialSidebarState,
  SidebarContextProvider,
} from "../../store/sidebar/context";
import { SidebarReducer } from "../../store/sidebar/reducer";

const Layout = () => {
  const [SidebarState, SidebarDispatch] = useReducer(
    SidebarReducer,
    initialSidebarState
  );
  const { showSidebar } = SidebarState;

  return (
    <SidebarContextProvider value={{ SidebarState, SidebarDispatch }}>
      <main className="h-full w-full">
        <Navbar />
        <section className="flex w-full">
          <Sidebar />
          <div
            className={
              "sm:pt-24 pt-20 gap-10 xl:pr[2.5%] pr-[4%] h-fit min-h-screen w-full sm:bg-background bg-white duration-700 transform ease-in-out transition-all " +
              (showSidebar ? "xl:pl-[22.5%] lg:pl-[23%] sm:pl-[24%] pl-[4%]" : "xl:pl-[7%] md:pl-[8%] sm:pl-[10%] pl-[4%]")
            }
          >
            <div className="mb-10">
              <Outlet />
            </div>
          </div>
        </section>
      </main>
    </SidebarContextProvider>
  );
};

export default Layout;
