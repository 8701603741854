import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import Abutton from "../../components/atoms/Abutton";
import AfilterDateInput from "../../components/atoms/AfilterDateInput";
import CircularLoad from "../../components/atoms/loading/CircularLoad";
import Loading from "../../components/atoms/loading/Loading";
import Pagination from "../../components/atoms/pagination/Pagination";
import MfilterGeneral from "../../components/molecules/MfilterGeneral";
import MRevenueCard from "../../components/molecules/MrevenueCard";
import { IRetailRevenue } from "../../core/retail_revenue/entities";
import { listSort } from "../../core/retail_revenue/entities";
import { useFetchRetailRevenues } from "../../hooks/retail_revenue";
import { ActionType } from "../../store/revenue/action";
import RevenueContext from "../../store/revenue/context";
import { formatDate, formatRupiah } from "../../helpers/common";
import { defineds } from "../../utils/date";
import generateCSV from "../../utils/generateCSV";
import {
  applyDateFilter,
  handleChangeSort,
  handleSearchCode,
  resetDateFilter,
} from "../../helpers/helper";
import { useQueries } from "../../helpers/QuerySearchParams";
import { UseOutsideClick } from "../../helpers/useOutsideClick";
import { getRevenue } from "./helper";
import AdateRangePicker, { checkLabelColor } from "../../components/atoms/AdateRangePicker";

const Revenue = () => {
  const query = useQueries(useLocation);
  const { search } = useLocation();
  const navigate = useNavigate();
  const filterRef = useRef(null);
  const { RevenueDispatch, RevenueState } = useContext(RevenueContext);
  const { showFilter } = RevenueState;

  const [load, setLoad] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(
        query.get("date")?.split(",")[0] || formatDate(defineds.startOfMonth)
      ),
      endDate: new Date(
        query.get("date")?.split(",")[1] || formatDate(defineds.endOfMonth)
      ),
      key: "selection",
    },
  ]);
  const [sort, setSort] = useState<
    SingleValue<{ label: string; value: string }>
  >(listSort[0]);
  const [searchCode, setSearchCode] = useState("");

  const { data, isLoading, isError } = useFetchRetailRevenues({
    startDate: formatDate(defineds.startOfMonth),
    endDate: formatDate(defineds.endOfMonth),
  });

  const handleShowFilter = () => {
    if (showFilter) {
      setTimeout(() => {
        RevenueDispatch({
          type: ActionType.ShowFilter,
        });
      }, 300);
    } else {
      RevenueDispatch({
        type: ActionType.ShowFilter,
      });
    }
  };
  UseOutsideClick(filterRef, handleShowFilter);

  useEffect(() => {
    const sort = query.get("sort");
    const search = query.get("code");
    if (sort) {
      listSort.map((el) => (el.value === sort ? setSort(el) : ""));
    }
    if (search) {
      setSearchCode(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showFilter) {
      checkLabelColor(date);
    }
  }, [date, showFilter]);

  const exportToCsv = async () => {
    let offset = 0;
    let limit = data && data?.[1]?.total < 300 ? data?.[1].total : 300;
    const datacsv: IRetailRevenue[] = [];
    while (data && offset < data?.[1].total) {
      const data = [...(await getRevenue(limit, offset, setLoad, date))];
      await datacsv.push(...data);
      if (!load) {
        offset += limit;
      }
    }
    generateCSV(
      datacsv,
      `revenue-(${formatDate(date[0].startDate, "DD/MM/YYYY")}-${formatDate(
        date[0].endDate,
        "DD/MM/YYYY"
      )})`
    );
  };

  if (isError) {
    return <h1>Something went wrong! Please refresh the page</h1>;
  } else if (load) {
    return (
      <div className="z-50 h-4/5 w-3/4 overflow-hidden flex justify-center items-center fixed">
        <CircularLoad height={50} width={50} />
      </div>
    );
  }

  return (
    <main className="w-full">
      <section>
        <aside>
          <article>
            <h1 className="font-Bold sm:text-base text-sm">Revenues</h1>
          </article>

          <article className="sm:flex hidden justify-between items-center mt-2">
            <AfilterDateInput
              showFilter={showFilter}
              handleShowFilter={handleShowFilter}
              date={date}
              handleReset={() => resetDateFilter({ navigate, search, setDate })}
              applyFilter={() => applyDateFilter({ date, navigate, search })}
            />
            <div>
              <Abutton
                onClick={exportToCsv}
                title="Export CSV"
                theme="secondary"
              />
            </div>

            {showFilter && (
              <div ref={filterRef} className="fixed z-50 my-3 top-40">
                <AdateRangePicker
                  date={date}
                  handleChangeDate={(item) => setDate([item.selection])}
                />
              </div>
            )}
          </article>
        </aside>

        <aside className="grid sm:grid-cols-3 grid-cols-2 gap-4 bg-white mt-3 shadow-md rounded-md p-4 text-xs">
          <div className="sm:col-auto col-span-2 sm:border-r border-r-0 sm:border-b-0 border-b sm:pb-0 pb-1">
            <h1 className="text-textSecondary font-rom">Total Transaction</h1>
            <h1 className="mt-3">{data?.[1]?.total}</h1>
          </div>
          <div className="border-r">
            <h1 className="text-textSecondary font-rom">Gross Profit (IDR)</h1>
            <h1 className="mt-3">
              {formatRupiah(data?.[2].total_admin_fee || 0)}
            </h1>
          </div>
          <div>
            <h1 className="text-textSecondary font-rom">Net Profit (IDR)</h1>
            {data?.[2].total_admin_fee && data[2].total_buyer_shipping_cost && (
              <h1 className="mt-3">
                {formatRupiah(
                  data?.[2]?.total_admin_fee -
                    data?.[2]?.total_buyer_shipping_cost
                )}
              </h1>
            )}
          </div>
        </aside>
      </section>
      <section>
        <section className="mt-4 sm:bg-white sm:rounded-lg sm:shadow-md w-auto top-[4.2rem] sticky">
          <MfilterGeneral
            sort={sort}
            handleChangeSort={(
              sort: SingleValue<{ label: string; value: string }>
            ) => handleChangeSort({ navigate, search, setSort, sort })}
            listSort={listSort}
            search={searchCode}
            setSearch={setSearchCode}
            handleSearch={() =>
              handleSearchCode({ search, searchCode, navigate })
            }
          />
        </section>

        {isLoading ? (
          <div className="w-full flex justify-center mt-20">
            <Loading />
          </div>
        ) : data?.[0] && data?.[0].length > 0 ? (
          <section className="sm:mt-5 mt-8 pb-10">
            <div className="flex flex-col gap-y-5">
              {data?.[0].map((item: IRetailRevenue, idx) => (
                <MRevenueCard key={idx} data={item} />
              ))}
            </div>
            <div className="my-5">
              <Pagination
                length={data?.[0].length || 0}
                total={data?.[1].total || 0}
                isTable={false}
              />
            </div>
          </section>
        ) : (
          <div className="flex w-full justify-center sm:my-20 my-10 text-textPrimary sm:text-base text-xs">
            Data Not Found.
          </div>
        )}
      </section>
    </main>
  );
};

export default Revenue;
