import { createContext } from "react";
import { IRevenueContext, IRevenueState } from "./state";

export const initialRevenueState: IRevenueState = {
  showFilter: false,
  chip: [],
};

const RevenueContext = createContext<IRevenueContext>({
  RevenueState: initialRevenueState,
  RevenueDispatch: () => undefined,
});

export const RevenueContextProvider = RevenueContext.Provider;
export const RevenueContextConsumer = RevenueContext.Consumer;

export default RevenueContext;
