import { IBuiltinSort, IPagination } from "../../core/helpers/helper";
import {
  ERetailTransactionTicketSortField,
  PRetailTransactionTicketFilter,
} from "../../core/retail_transaction_ticket/entities";
import RetailTransactionTicketRepository from "../../core/retail_transaction_ticket/repository/repository";
import RetailTransactionTicketUsecase from "../../core/retail_transaction_ticket/usecase/usecase";

export class RetailTransactionTicketService {
  static getRetailTransactionTickets = async (
    pagination: IPagination,
    filter: PRetailTransactionTicketFilter,
    sorts: IBuiltinSort<ERetailTransactionTicketSortField>[]
  ) => {
    try {
      const retailTransactionTicketRepo =
        new RetailTransactionTicketRepository();
      const retailTransactionTicketUsecase = new RetailTransactionTicketUsecase(
        retailTransactionTicketRepo
      );
      const retailTransactionTicket =
        await retailTransactionTicketUsecase.getRetailTransactionTickets(
          pagination,
          filter,
          sorts
        );
      return retailTransactionTicket;
    } catch (error) {
      console.log(error);
    }
  };

    static getRetailTransactionTicketCounter = async (
      filter: PRetailTransactionTicketFilter
    ) => {
      try {
        const retailTransactionTicketRepo =
          new RetailTransactionTicketRepository();
        const retailTransactionTicketUsecase = new RetailTransactionTicketUsecase(
          retailTransactionTicketRepo
        );
        const counter =
          await retailTransactionTicketUsecase.getRetailTransactionTicketCounter(
            filter
          );
        return counter;
      } catch (error) {
        console.log(error);
      }
    };

  static getRetailTransactionTicket = async (code: string) => {
    try {
      const retailTransactionTicketRepo =
        new RetailTransactionTicketRepository();
      const retailTransactionTicketUsecase = new RetailTransactionTicketUsecase(
        retailTransactionTicketRepo
      );
      const retailTransactionTicket =
        await retailTransactionTicketUsecase.getRetailTransactionTicket(code);
      return retailTransactionTicket;
    } catch (error) {
      console.log(error);
    }
  };
}
