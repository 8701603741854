export enum EAllowedRoles {
  ADMIN = "admin",
  PARTNER = "partner",
  OFFICIAL = "official",
}

export const listMenu = [
  {
    type: "Home",
    link: "/home",
    target: "parent",
    allowedRoles: [
      EAllowedRoles.ADMIN,
      EAllowedRoles.OFFICIAL,
      EAllowedRoles.PARTNER,
    ],
  },
  {
    type: "Stores",
    link: `${process.env.REACT_APP_AMIDIN_API_BASE_URL}/admin/retail-shop`,
    target: "blank",
    allowedRoles: [
      EAllowedRoles.ADMIN,
      EAllowedRoles.OFFICIAL,
      EAllowedRoles.PARTNER,
    ],
  },
  {
    type: "Products",
    link: `${process.env.REACT_APP_AMIDIN_API_BASE_URL}/admin/retail-product`,
    target: "blank",
    allowedRoles: [
      EAllowedRoles.ADMIN,
      EAllowedRoles.OFFICIAL,
      EAllowedRoles.PARTNER,
    ],
  },
  {
    type: "Tickets",
    link: "/ticket",
    target: "parent",
    allowedRoles: [
      EAllowedRoles.ADMIN,
      EAllowedRoles.OFFICIAL,
      EAllowedRoles.PARTNER,
    ],
  },
  {
    type: "Transaction (Products)",
    link: "/transaction-product",
    target: "parent",
    allowedRoles: [EAllowedRoles.ADMIN, EAllowedRoles.PARTNER],
  },
  {
    type: "Transaction (Tickets)",
    link: "/transaction-ticket",
    target: "parent",
    allowedRoles: [EAllowedRoles.ADMIN, EAllowedRoles.PARTNER],
  },
  {
    type: "Revenues",
    link: "/revenue",
    target: "parent",
    allowedRoles: [EAllowedRoles.ADMIN],
  },
  {
    type: "Logout",
    link: "/login",
    target: "parent",
    allowedRoles: [
      EAllowedRoles.ADMIN,
      EAllowedRoles.OFFICIAL,
      EAllowedRoles.PARTNER,
    ],
  },
];
