export enum ActionType {
    ShowFilter,
    HandleChip,
  }
  
  export interface ShowFilter {
    type: ActionType.ShowFilter;
  }
  
  export interface HandleChip {
    type: ActionType.HandleChip;
    payload: any[];
  }
  
  export type TransactionTicketAction = ShowFilter | HandleChip;
  