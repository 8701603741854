export interface IRetailRevenue {
  admin_fee: number;
  admin_fee_percentage: number;
  buyer_id: number;
  buyer_shipping_cost: number;
  buyer_username: number;
  id: number;
  price: number;
  quantity: number;
  retail_product_id: number;
  retail_shop_id: number;
  retail_transaction_code: string;
  seller_payout: number;
  total: number;
  created_at: string;
  retail_product_display_image_path: string;
  retail_product_name: string;
  retail_product_stock_id: number;
  retail_product_stock_label: string;
  retail_product_type: string;
  retail_shop_logo: string;
  retail_shop_name: string;
  weight: number;
}
export type PRetailRevenue = Partial<IRetailRevenue>;

export interface IRetailRevenueFilter {
  date: string;
  code: string;
}
export type PRetailRevenueFilter = Partial<IRetailRevenueFilter>;

export enum ERetailRevenueSortField {
  newest = "created_at desc",
  oldest = "created_at asc",
}

export const listSort = [
  {
    label: "Newest Transaction",
    value: `${ERetailRevenueSortField.newest}`,
  },
  {
    label: "Oldest Transaction",
    value: `${ERetailRevenueSortField.oldest}`,
  },
];
