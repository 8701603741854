import jwtDecode from "jwt-decode";
import moment from "moment";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";

export const checkToken = (token: string) => {
  if (!token) return false;
  const decoded: { exp: number } = jwtDecode(token);
  if (decoded.exp * 1000 < moment().valueOf()) {
    return false;
  } else {
    return true;
  }
};

const ProtectedRoutes = () => {
  const { auth } = useAuth();
  return checkToken(auth.token) ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
