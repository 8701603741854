import React, { useContext } from "react";
import { listmenu, logo, notification } from "../../assets/icons";
import { renderRole } from "../../helpers/common";
import useAuth from "../../helpers/useAuth";
import { ActionType } from "../../store/sidebar/action";
import SidebarContext from "../../store/sidebar/context";
import "../../styles/navbar.css";

function Navbar() {
  const { SidebarDispatch } = useContext(SidebarContext);
  const { auth } = useAuth();
  const { role } = auth;

  const handleShowSidebar = () => {
    SidebarDispatch({ type: ActionType.ShowSidebar });
  };
  return (
    <nav className="fixed box-border z-50 flex flex-wrap items-center justify-between md:px-8 px-6 py-5 w-full bg-white shadow-md">
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center mx-auto w-1/2 justify-start">
          <div className="sm:flex hidden">
            <h1 className="text-primary mr-1.5 md:text-xl text-base text-center">
              DEPATU
            </h1>
            <h1 className="text-black md:text-xl text-base">Official Store</h1>
          </div>
          <div className="sm:hidden flex">
            <img
              onClick={handleShowSidebar}
              src={listmenu}
              className="w-5 h-5 cursor-pointer"
              alt="-"
            />
          </div>
        </div>

        <div className="flex items-center justify-end w-1/2">
          <div>
            <img
              src={notification}
              className="w-5 h-5 cursor-pointer icon"
              alt="-"
            />
          </div>
          <div className="sm:inline hidden border-l bg-iconColor border-iconColor h-6 mx-4" />
          <div className="sm:flex hidden items-center">
            <img src={logo} className="w-5 h-5 cursor-pointer" alt="-" />
            <h1 className="text-textPrimary ml-2">{renderRole(role)}</h1>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
