import Swal from "sweetalert2";
import "./index.css";

function Snackbar(color, title) {
  const Toast = Swal.mixin({
    background: color,
    toast: true,
    position: "top-end",
    width: "100%",
    showClass: {
      backdrop: "swal2-animation",
      popup: "",
      icon: "",
    },
    hideClass: {
      popup: "",
    },
    showConfirmButton: false,
    timer: 2000,
    customClass: "swall-class",
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  Toast.fire({
    html: "<pre>" + title + "</pre>",
    animation: false,
  });
}

export default Snackbar;
