import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { IMeta } from "../../../core/helpers/helper";
import { IRetailShop } from "../../../core/retail_shop/entities";
import Pagination from "../../atoms/pagination/Pagination";
import { eye } from "../../../assets/icons";

interface IRetailShopTable {
  data: [IRetailShop[], IMeta] | undefined;
}

const RetailShopTable: React.FC<IRetailShopTable> = ({ data }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="w-full my-5">
      <table className="w-full border relative text-center text-sm">
        <thead className="">
          <tr className="h-18 sticky z-30 top-20 bg-white shadow-sm">
            <th className="border-r w-1/5">Display Image</th>
            <th className="border-r w-1/5">Name</th>
            <th className="border-r w-1/5">Status</th>
            <th className="border-r w-1/4">Description</th>
            <th className="border-r">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.[0]?.map((el: IRetailShop) => (
            <tr key={el.id} className="border h-24 font-rom hover:bg-gray-50">
              <td className="border-r h-auto flex justify-center">
                <div className="flex justify-center items-center bg-white shadow-md w-28 my-5 rounded-md">
                  <img
                    src={el.logo_path}
                    alt="-"
                    className="w-full"
                    loading="lazy"
                  />
                </div>
              </td>
              <td className="border-r text-sm">{el.name || "-"}</td>

              <td className="border-r ">
                {el.is_banned ? (
                  <h1 className="text-primary">Banned</h1>
                ) : el.is_closed ? (
                  <h1 className="text-primary">Closed</h1>
                ) : (
                  <h1 className="text-success">Open</h1>
                )}
              </td>

              <td className="border-r px-4">
                <p>{el.description}</p>
              </td>
              <td>
                <div className="flex justify-center">
                  <img
                    data-tip
                    data-for="detail"
                    className="cursor-pointer md:w-6"
                    onClick={() => navigate(`${pathname}/${el.id}`)}
                    src={eye}
                    alt="detail"
                  />
                  <ReactTooltip id="detail" type="info">
                    <span>Details</span>
                  </ReactTooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        length={data?.[0]?.length || 0}
        total={data?.[1]?.total || 0}
        isTable={true}
      />
    </div>
  );
};
export default RetailShopTable;
