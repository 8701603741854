import React, { FC } from "react";

interface IMproductListCardProps {
  image: string;
  name: string;
  variant: string;
  quantity: number;
  border?: boolean;
}

const MproductListCard: FC<IMproductListCardProps> = ({
  image,
  name,
  variant,
  quantity,
  border = false,
}) => {
  return (
    <div
      className={
        "w-full flex justify-between  " +
        (border && "border border-stroke rounded-md p-4")
      }
    >
      <div className="flex gap-3 text-textPrimary">
        <div className="w-12 flex justify-center">
          <img src={image} alt="-" className="rounded-md shadow-md w-full" />
        </div>
        <div>
          <h1 className="font-med leading-tight sm:text-sm text-xs">{name}</h1>
          <p className="font-rom sm:text-xs text-xxs mt-0.5">
            Variant : {variant} | Quantity : {quantity}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MproductListCard;
