import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { TicketRequest } from "../../../apis/ticket/TicketRequest";
import { cancel } from "../../../assets/icons";
import { ActionType } from "../../../store/ticket/action";
import TicketContext from "../../../store/ticket/context";
import Button from "../../atoms/button/Button";
import TextArea from "../../atoms/input/TextArea";

const RevisionTicketModal = () => {
  const { TicketDispatch, TicketState } = useContext(TicketContext);
  const { showRevisiTicket } = TicketState;
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [RevisionReason, setRevisionReason] = useState("");

  const goBack = () => {
    TicketDispatch({
      type: ActionType.ShowRevisiTicket,
      payload: {
        id: 0,
        status: false,
      },
    });
  };

  const RevisionTicketModal = async () => {
    setSubmitting(true);
    await mutation.mutate();
  };

  const mutation = useMutation(
    () =>
      TicketRequest.revisiTicket(
        showRevisiTicket.id.toString(),
        RevisionReason
      ),
    {
      onMutate: () => {
        console.log("mutate Revision ticket");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else {
          setSubmitting(false);
          goBack();
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-1/4 h-auto rounded-xl my-auto mx-auto ">
        <div className="flex w-full justify-between items-center px-8 pb-5 pt-8">
          <h1 className="text-xl">Revision Ticket</h1>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <hr />
        <div className="px-10 py-5">
          <div className="flex flex-col items-center">
            <TextArea
              name="Revision_reason"
              handleChange={(e: any) => setRevisionReason(e.target.value)}
            />
          </div>

          <div className="flex justify-center my-4 outline-none">
            <Button
              isSubmitting={isSubmitting}
              loadColor={"white"}
              type={"button"}
              height={10}
              handleClick={RevisionTicketModal}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Proceed"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevisionTicketModal;
