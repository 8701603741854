import { createContext } from "react";
import { ITicketContext, ITicketState } from "./state";

export const initialTicketState: ITicketState = {
  showFilter: false,
  showRejectTicket: {
    status: false,
    id: 0,
  },
  showRevisiTicket: {
    status: false,
    id: 0,
  },
  chip: [],
};

const TicketContext = createContext<ITicketContext>({
  TicketState: initialTicketState,
  TicketDispatch: () => undefined,
});

export const TicketContextProvider = TicketContext.Provider;
export const TicketContextConsumer = TicketContext.Consumer;

export default TicketContext;
