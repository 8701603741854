import { ActionType, TicketAction } from "./action";
import { ITicketState } from "./state";

export const TicketReducer = (state: ITicketState, action: TicketAction) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.ShowRejectTicket:
      let showRejectTicket = action.payload;
      document.body.classList[showRejectTicket.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showRejectTicket };
    case ActionType.ShowRevisiTicket:
      let showRevisiTicket = action.payload;
      document.body.classList[showRevisiTicket.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showRevisiTicket };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
  }
};
