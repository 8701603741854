import AxiosInstance from "../../config/AxiosInstance";
import { formatDate } from "../../helpers/common";

export const getRevenue = async (
  limit: number,
  offset: number,
  setLoad: (load: boolean) => void,
  date: any[]
) => {
  try {
    setLoad(true);
    const results = await AxiosInstance.Mercury().get(
      `admin/retail-revenue`,
      {
        params: {
          limit: limit,
          created_at: `${formatDate(date[0].startDate)},${formatDate(
            date[0].endDate
          )}`,
          offset: offset,
          sort: `created_at desc`,
        },
      }
    );
    const result = await results.data.data;
    setLoad(false);
    return result;
  } catch (error) {
    console.log(error);
  }
};
