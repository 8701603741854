import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { EAllowedRoles } from "../core/auth/entities";
import ProtectedRoutes from "../helpers/ProtectedRoutes";
import RequireAuth from "../helpers/RequireAuth";

import LoginPage from "../pages/auth/Login";
import UnauthorizedPage from "../pages/auth/UnauthorizedPage";
import Home from "../pages/home/Home";
import RetailShopRoute from "./retail_shop/RetailShopRoute";
import RevenueRoute from "./revenue/RevenueRoute";
import TicketRoute from "./ticket/TicketRoute";
import TransactionProductRoute from "./transaction_product/TransactionProductRoute";
import TransactionTicketRoute from "./transaction_ticket/TransactionTicketRoute";

const MainRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="unauthorized" element={<UnauthorizedPage />} />

        <Route path="/" element={<ProtectedRoutes />}>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  EAllowedRoles.ADMIN,
                  EAllowedRoles.OFFICIAL,
                  EAllowedRoles.PARTNER,
                ]}
              />
            }
          >
            <Route path="home" element={<Home />} />
            <Route path="retail-shop/*" element={<RetailShopRoute />} />
            <Route path="ticket/*" element={<TicketRoute />} />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={[EAllowedRoles.ADMIN, EAllowedRoles.PARTNER]}
              />
            }
          >
            <Route
              path="transaction-ticket/*"
              element={<TransactionTicketRoute />}
            />
            <Route
              path="transaction-product/*"
              element={<TransactionProductRoute />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[EAllowedRoles.ADMIN]} />}>
            <Route path="revenue/*" element={<RevenueRoute />} />
          </Route>
        </Route>

        <Route path="/" element={<Navigate replace to="home" />} />
      </Route>
    </Routes>
  );
};

export default MainRoute;
