import { IBuiltinSort, IMeta, IPagination, ISum } from "../../helpers/helper";
import {
  ERetailRevenueSortField,
  IRetailRevenue,
  PRetailRevenueFilter,
} from "../entities";
import IRetailRevenueRepository from "../repository";
import IRetailRevenueUsecase from "../usecase";

export default class RetailRevenueUsecase implements IRetailRevenueUsecase {
  private RetailRevenueRepo: IRetailRevenueRepository;

  constructor(RetailRevenueRepo: IRetailRevenueRepository) {
    this.RetailRevenueRepo = RetailRevenueRepo;
  }

  async getRetailRevenues(
    pagination: IPagination,
    filter: PRetailRevenueFilter,
    sorts: IBuiltinSort<ERetailRevenueSortField>[]
  ): Promise<[IRetailRevenue[], IMeta, ISum]> {
    const [data, meta, sum] = await this.RetailRevenueRepo.getRetailRevenues(
      pagination,
      filter,
      sorts
    );
    return [data, meta, sum];
  }

  async getRetailRevenue(code: string): Promise<IRetailRevenue> {
    const RetailTransaction = await this.RetailRevenueRepo.getRetailRevenue(
      code
    );
    return RetailTransaction;
  }
}
