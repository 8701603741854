import React, { useReducer } from "react";
import { Route, Routes } from "react-router";
import TransactionTicket from "../../pages/transaction_ticket/TransactionTicket";
import TransactionTicketDetail from "../../pages/transaction_ticket/TransactionTicketDetail";
import TransactionTicketMobile from "../../pages/transaction_ticket/TransactionTicketMobile";
import { initialTransactionTicketState, TransactionTicketContextProvider } from "../../store/transaction_ticket/context";
import { TransactionTicketReducer } from "../../store/transaction_ticket/reducer";

const TransactionTicketRoute = () => {
  const [TransactionTicketState, TransactionTicketDispatch] = useReducer(
    TransactionTicketReducer,
    initialTransactionTicketState
  );
  return (
    <TransactionTicketContextProvider
      value={{ TransactionTicketState, TransactionTicketDispatch }}
    >
      <Routes>
        <Route path="/" element={<TransactionTicket />} />
        <Route path="/:code" element={<TransactionTicketDetail />} />
        <Route path="/detail/:code" element={<TransactionTicketMobile />} />
      </Routes>
    </TransactionTicketContextProvider>
  );
};

export default TransactionTicketRoute;
