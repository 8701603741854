import React, { useReducer } from "react";
import { Route, Routes } from "react-router";
import Revenue from "../../pages/revenue/Revenue";
import RevenueDetail from "../../pages/revenue/RevenueDetail";
import {
  initialRevenueState,
  RevenueContextProvider,
} from "../../store/revenue/context";
import { RevenueReducer } from "../../store/revenue/reducer";

const RevenueRoute = () => {
  const [RevenueState, RevenueDispatch] = useReducer(
    RevenueReducer,
    initialRevenueState
  );
  return (
    <RevenueContextProvider value={{ RevenueState, RevenueDispatch }}>
      <Routes>
        <Route path="/" element={<Revenue />} />
        <Route path="/:code" element={<RevenueDetail />} />
      </Routes>
    </RevenueContextProvider>
  );
};

export default RevenueRoute;
