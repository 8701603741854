import _ from "lodash";
import qs from "qs";
import AxiosInstance from "../../../config/AxiosInstance";
import { IBuiltinSort, IMeta, IPagination, ISum } from "../../helpers/helper";
import {
  ERetailRevenueSortField,
  IRetailRevenue,
  PRetailRevenueFilter,
} from "../entities";
import IRetailRevenueRepository from "../repository";

export default class RetailRevenueRepository
  implements IRetailRevenueRepository
{
  // api routes
  private RETAIL_REVENUE_ROUTE = "/admin/retail-revenue";

  buildFilter(filter: PRetailRevenueFilter) {
    const created_at = filter.date;
    const retail_transaction_code = filter.code
    const clonedFilter = { ...filter, created_at , retail_transaction_code};
    delete clonedFilter.date;
    delete clonedFilter.code;
    return {
      ...clonedFilter
    };
  }

  buildSort(sort: IBuiltinSort<ERetailRevenueSortField>[]) {
    let strSort = "";
    sort.map((el) => (strSort += el.field));
    return strSort;
  }

  async getRetailRevenues(
    pagination: IPagination,
    filter: PRetailRevenueFilter,
    sorts: IBuiltinSort<ERetailRevenueSortField>[]
  ): Promise<[IRetailRevenue[], IMeta, ISum]> {
    try {
      const params = _.pickBy(
        {
          ...pagination,
          ...this.buildFilter(filter),
        },
        (val) => val
      );

      const sort = this.buildSort(sorts);

      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_REVENUE_ROUTE}?sort=${sort}`,
        {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
        }
      );
      const { data, meta , sum} = response.data;
      return [data, meta, sum];
    } catch (error) {
      throw error;
    }
  }

  async getRetailRevenue(code: string): Promise<IRetailRevenue> {
    try {
      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_REVENUE_ROUTE}/${code}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }
}
