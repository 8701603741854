import { createContext } from "react";
import { ISidebarContext, ISidebarState } from "./state";

export const initialSidebarState: ISidebarState = {
  showSidebar: window.innerWidth >= 640 ? true : false,
};

const SidebarContext = createContext<ISidebarContext>({
  SidebarState: initialSidebarState,
  SidebarDispatch: () => undefined,
});

export const SidebarContextProvider = SidebarContext.Provider;
export const SidebarContextConsumer = SidebarContext.Consumer;

export default SidebarContext;
