import { createContext, useState } from "react";

interface IAuth {
  role: string;
  token: string;
  username: string
}

export interface IAuthContext {
  auth: IAuth;
  setAuth: (auth: IAuth) => void;
}

const AuthContext = createContext<IAuthContext | any>({});

export const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
