import React, { useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import StockTicket from "../../pages/ticket/StockTicket";
import StockTicketDetail from "../../pages/ticket/sub_Ticket/StockTicketDetail";
import TicketDetail from "../../pages/ticket/sub_Ticket/TicketDetail";
import Ticket from "../../pages/ticket/Ticket";
import {
  initialTicketState,
  TicketContextProvider,
} from "../../store/ticket/context";
import { TicketReducer } from "../../store/ticket/reducer";

const TicketRoute = () => {
  const [TicketState, TicketDispatch] = useReducer(
    TicketReducer,
    initialTicketState
  );

  return (
    <TicketContextProvider value={{ TicketState, TicketDispatch }}>
      <Routes>
        <Route path="/" element={<Ticket />} />
        <Route path="/:id" element={<TicketDetail />} />,
        <Route path="/:id/stock-ticket" element={<StockTicket />} />,
        <Route
          path="/:id/stock-ticket/:variantId"
          element={<StockTicketDetail />}
        />
      </Routes>
    </TicketContextProvider>
  );
};

export default TicketRoute;
