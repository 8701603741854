import Snackbar from "../../components/atoms/toast/Snackbar";
import AxiosInstance from "../../config/AxiosInstance";

export class TicketRequest {
  static getTicket = async () => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await AxiosInstance.Dicket().get(
        `admin/ticket-bundle/`,
        {
          params: {
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "created_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  static getTicketDetail = async (id: string | undefined) => {
    try {
      const results: any = await AxiosInstance.Dicket().get(
        `admin/ticket-bundle/${id}/`
      );
      const data = results.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  static getTicketVariant = async (ticket_bundle_id: string | undefined) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await AxiosInstance.Dicket().get(`admin/ticket/`, {
        params: {
          ticket_bundle_id,
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  static getTicketVariantById = async (id: string | undefined) => {
    try {
      const results: any = await AxiosInstance.Dicket().get(
        `admin/ticket/${id}/`
      );
      const data = results.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  static acceptTicket = async (id: string | undefined) => {
    try {
      const result = await AxiosInstance.Dicket().post(
        `/admin/ticket-bundle/${id}/approve/`
      );
      Snackbar("#00C62E", "Accept Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        Snackbar(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        Snackbar("#ed4846", error.response.data.errors || "Accept Failed");
      }
    }
  };

  static rejectTicket = async (
    id: string | undefined,
    rejected_reason: string
  ) => {
    try {
      const result = await AxiosInstance.Dicket().post(
        `/admin/ticket-bundle/${id}/reject/`,
        { rejected_reason }
      );
      Snackbar("#00C62E", "Reject Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        Snackbar(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        Snackbar("#ed4846", error.response.data.errors || "Reject Failed");
      }
    }
  };

  static revisiTicket = async (
    id: string | undefined,
    rejected_reason: string
  ) => {
    try {
      const result = await AxiosInstance.Dicket().post(
        `/admin/ticket-bundle/${id}/decline/`,
        { rejected_reason }
      );
      Snackbar("#00C62E", "Revision Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        Snackbar(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        Snackbar("#ed4846", error.response.data.errors || "Revision Failed");
      }
    }
  };
}
