import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Atransaction: FC<IIconProps> = ({
  stroke = "#666666",
  width = "29",
  height = "26",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9375 24C11.9375 25.0701 11.0701 25.9375 10 25.9375C8.92995 25.9375 8.0625 25.0701 8.0625 24C8.0625 22.9299 8.92995 22.0625 10 22.0625C11.0701 22.0625 11.9375 22.9299 11.9375 24Z"
        fill={stroke}
        stroke={stroke}
        strokeWidth="0.125"
      />
      <path
        d="M24.9375 24C24.9375 25.0701 24.0701 25.9375 23 25.9375C21.9299 25.9375 21.0625 25.0701 21.0625 24C21.0625 22.9299 21.9299 22.0625 23 22.0625C24.0701 22.0625 24.9375 22.9299 24.9375 24Z"
        fill={stroke}
        stroke={stroke}
        strokeWidth="0.125"
      />
      <path
        d="M5.2875 6H27.7125L24.4125 17.55C24.2948 17.9692 24.0426 18.3381 23.6948 18.6001C23.3471 18.862 22.9229 19.0025 22.4875 19H10.5125C10.0771 19.0025 9.65293 18.862 9.30515 18.6001C8.95738 18.3381 8.70524 17.9692 8.5875 17.55L4.0625 1.725C4.0027 1.51594 3.8764 1.33207 3.70271 1.20125C3.52903 1.07042 3.31744 0.999771 3.1 1H1"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
