import { IBuiltinSort, IMeta, IPagination } from "../../helpers/helper";
import {
  ERetailTransactionTicketSortField,
  IRetailTransactionTicket,
  IRetailTransactionTicketCounter,
  PRetailTransactionTicketFilter,
} from "../entities";
import IRetailTransactionTicketRepository from "../repository";
import IRetailTransactionTicketUsecase from "../usecase";

export default class RetailTransactionTicketUsecase
  implements IRetailTransactionTicketUsecase
{
  private RetailTransactionTicketRepo: IRetailTransactionTicketRepository;

  constructor(RetailTransactionTicketRepo: IRetailTransactionTicketRepository) {
    this.RetailTransactionTicketRepo = RetailTransactionTicketRepo;
  }

  async getRetailTransactionTickets(
    pagination: IPagination,
    filter: PRetailTransactionTicketFilter,
    sorts: IBuiltinSort<ERetailTransactionTicketSortField>[]
  ): Promise<[IRetailTransactionTicket[], IMeta]> {
    const [data, meta] =
      await this.RetailTransactionTicketRepo.getRetailTransactionTickets(
        pagination,
        filter,
        sorts
      );
    return [data, meta];
  }

  async getRetailTransactionTicket(code: string): Promise<IRetailTransactionTicket> {
    const RetailTransaction =
      await this.RetailTransactionTicketRepo.getRetailTransactionTicket(code);
    return RetailTransaction;
  }

    async getRetailTransactionTicketCounter(
      filter: PRetailTransactionTicketFilter
    ): Promise<IRetailTransactionTicketCounter[]> {
      const data = await this.RetailTransactionTicketRepo.getRetailTransactionTicketCounter(
        filter
      );
      return data;
    }
}
