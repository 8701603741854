import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueries } from "../../../helpers/QuerySearchParams";

interface IPagination {
  length: number;
  total: number;
  isTable: boolean;
}

const Pagination: React.FC<IPagination> = ({ length, total, isTable }) => {
  const query = useQueries(useLocation);
  const navigate = useNavigate();
  const { search } = useLocation();
  const offsetParams = query.get("offset") || 0;
  const limitParams = query.get("limit") || 10;

  const handleDownPage = () => {
    let totalOffset = Number(offsetParams) - Number(limitParams);
    let new_search =
      typeof offsetParams === "string"
        ? search.replace(`offset=${offsetParams}`, `offset=${totalOffset}`)
        : `${search}&&offset=${totalOffset}`;
    navigate({
      search: new_search,
    });
  };

  const handleUpPage = () => {
    let totalOffset = Number(offsetParams) + Number(limitParams);
    let new_search =
      typeof offsetParams === "string"
        ? search.replace(`offset=${offsetParams}`, `offset=${totalOffset}`)
        : `${search}&&offset=${totalOffset}`;
    navigate({
      search: new_search,
    });
  };

  const setRows = (e: any) => {
    let value = e.target.value;
    let new_search = search.replace(`offset=${offsetParams}`, `offset=0`);

    if (typeof limitParams === "string") {
      new_search = new_search.replace(`limit=${limitParams}`, `limit=${value}`);
    } else {
      new_search = `${new_search}&&limit=${value}`;
    }
    navigate({
      search: new_search,
    });
  };

  const handleToFirstPage = () => {
    let new_search: any =
      typeof offsetParams === "string" &&
      search.replace(`offset=${offsetParams}`, `offset=0`);
    navigate({
      search: new_search,
    });
  };

  const handleToLastPage = () => {
    const summary =
      Number(limitParams) * Math.floor(total / Number(limitParams));
    let new_search: any =
      typeof offsetParams === "string"
        ? search.replace(`offset=${offsetParams}`, `offset=${summary}`)
        : `${search}&&offset=${summary}`;
    navigate({
      search: new_search,
    });
  };

  return (
    <div
      className={
        "w-full flex justify-between items-center  " +
        (isTable ? "border-2 border-t-0 border-stroke px-8 h-20 " : "")
      }
    >
      <div className="flex items-center">
        <h1 className="sm:text-sm text-xs">Rows</h1>
        <select
          onChange={setRows}
          name="rows"
          value={limitParams || "10"}
          className="h-12 ml-4 focus:outline-none sm:text-sm text-xs cursor-pointer"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      <div className="flex flex-col items-center">
        <div className="flex items-center">
          {Number(offsetParams) === 0 ? (
            <h1 className="text-gray-400 cursor-default mr-3 sm:text-sm text-xs">1</h1>
          ) : (
            <h1
              onClick={handleToFirstPage}
              className="cursor-pointer text-black hover:text-opacity-70 sm:text-sm text-xs mr-3"
            >
              1
            </h1>
          )}
          <div className="h-12 w-12 mr-1 flex justify-center items-center rounded-full">
            {Number(offsetParams) === 0 ? (
              <h1 className="sm:text-sm text-xs text-gray-400 cursor-default">Prev</h1>
            ) : (
              <h1
                onClick={handleDownPage}
                className="sm:text-sm text-xs text-black cursor-pointer hover:text-opacity-70"
              >
                Prev
              </h1>
            )}
          </div>
          <div className="flex h-12 rounded-full sm:text-sm text-xs">
            <div className="flex justify-center items-center cursor-default sm:text-sm text-xs">
              {Number(offsetParams) / Number(limitParams) + 1}
            </div>
          </div>
          <div className="h-12 w-12 ml-1 flex justify-center items-center rounded-full sm:text-sm text-xs">
            {length === total - Number(offsetParams) ? (
              <h1 className="sm:text-sm text-xs text-gray-400 cursor-default">Next</h1>
            ) : (
              <h1
                onClick={handleUpPage}
                className="sm:text-sm text-xs text-black cursor-pointer hover:text-opacity-70"
              >
                Next
              </h1>
            )}
          </div>
          {length === total - Number(offsetParams) ? (
            <h1 className="text-gray-400 cursor-default ml-3 sm:text-sm text-xs">
              {`${Math.ceil(total / (limitParams as any))}`}
            </h1>
          ) : (
            <h1
              onClick={handleToLastPage}
              className="cursor-pointer text-black sm:text-sm text-xs hover:text-opacity-70 ml-3"
            >
              {`${Math.ceil(total / Number(limitParams))}`}
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
