import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Arevenue: FC<IIconProps> = ({
  stroke = "#666666",
  width = "26",
  height = "22",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25 21H1V1" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23 3L13 13L9 9L1 17" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23 8V3H18" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
