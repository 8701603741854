import React, { Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { breadcrumbnext } from "../../assets/icons";
import Loading from "../../components/atoms/loading/Loading";
import MproductListCard from "../../components/molecules/MproductListCard";
import { renderStatusColor } from "../../components/molecules/MtransactionTicketCard";
import { useFetchRetailTransactionTicket } from "../../hooks/retail_transaction_ticket";
import { formatDate, formatRupiah } from "../../helpers/common";

const TransactionTicketMobile = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data } = useFetchRetailTransactionTicket(params?.code || "");

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Suspense
      fallback={
        <div className="w-full flex justify-center mt-20">
          <Loading />
        </div>
      }
    >
      {data && (
        <main className="text-textPrimary text-xs">
          <div onClick={goBack} className="flex gap-1">
            <img src={breadcrumbnext} alt="-" className="w-2" />
            <span className="text-xs">Back</span>
          </div>
          <hr className="mt-3" />
          <section className="mt-4">
            <article>
              <h1 className="text-xs">{data?.code}</h1>
              <aside className="mt-3">
                <div className="flex justify-between font-rom">
                  <h1 className="text-textSecondary">Transaction Date</h1>
                  <h1>{formatDate(data?.created_at, "lll")}</h1>
                </div>
                <div className="flex justify-between font-rom mt-1">
                  <h1 className="text-textSecondary">Status</h1>
                  <h1 className={"font-med " + renderStatusColor(data.status)}>
                    {data.status}
                  </h1>
                </div>
              </aside>
            </article>
            <hr className="mt-3" />
            <article className="mt-3">
              <div>
                <h1>
                  Customer :{" "}
                  <span className="font-rom">{data.username}</span>
                </h1>
              </div>
              <hr className="my-2" />
              <div className="flex mt-5">
                <div className="w-2/3">
                  <MproductListCard
                    image={data.image_url}
                    name={data.title}
                    variant={
                      data.sub_title
                    }
                    quantity={data.quantity}
                  />
                </div>
                <div className="w-1/3 font-rom self-end flex justify-end pb-3">
                  <h1>{formatRupiah(data.price)}</h1>
                </div>
              </div>
              <hr className="mt-4" />
            </article>
            <article className="font-rom flex mt-3">
              <div className="w-2/3 flex justify-start flex-col gap-2 border-r">
                <span className="font-med">Total Selling</span>
              </div>
              <div className="w-1/3 flex flex-col items-end gap-2">
                <span className="font-med text-primary">
                  {formatRupiah(data.total_price)}
                </span>
              </div>
            </article>
          </section>
        </main>
      )}
    </Suspense>
  );
};

export default TransactionTicketMobile;
