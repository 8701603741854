import { SingleValue } from "react-select";
import { formatDate } from "./common";
import { defineds } from "../utils/date";

interface IhandleChangeSort {
  sort: SingleValue<{ label: string; value: string }>;
  search: string;
  setSort: (sort: any) => void;
  navigate: any;
}

export const handleChangeSort = ({
  sort,
  search,
  setSort,
  navigate,
}: IhandleChangeSort) => {
  const location = document.location.search;
  const query = new URLSearchParams(location);
  const prevSort = query.get("sort");
  let new_search = search;
  if (prevSort) {
    new_search = search.replace(
      `sort=${prevSort.split(" ").join("%20")}`,
      `sort=${sort?.value}`
    );
  } else {
    new_search = `${search}&&sort=${sort?.value}`;
  }
  setSort(sort);
  navigate({ search: new_search });
};

export interface IresetDateFilter {
  search: string;
  navigate: any;
  setDate: any;
}

export const resetDateFilter = ({
  search,
  navigate,
  setDate,
}: IresetDateFilter) => {
  const location = document.location.search;
  const query = new URLSearchParams(location);
  const date = query.get("date");
  let new_search = search;
  setDate([
    {
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
      key: "selection",
    },
  ]);
  if (date || date?.length === 0) {
    new_search = new_search.replace(
      `date=${date}`,
      `date=${formatDate(defineds.startOfMonth)},${formatDate(
        defineds.endOfMonth
      )}&&offset=0`
    );
  }
  navigate({ search: new_search });
};

export interface IapplyDateFilter {
  search: string;
  date: { startDate: string | Date; endDate: string | Date }[];
  navigate: any;
}

export const applyDateFilter = ({
  search,
  date,
  navigate,
}: IapplyDateFilter) => {
  const location = document.location.search;
  const query = new URLSearchParams(location);
  const queryDate = query.get("date");
  let new_search = search;

  if (queryDate || queryDate?.length === 0) {
    new_search = new_search.replace(
      `date=${queryDate}`,
      `date=${formatDate(date[0].startDate)},${formatDate(date[0].endDate)}`
    );
  } else {
    new_search = `${new_search}&&date=${formatDate(
      date[0].startDate
    )},${formatDate(date[0].endDate)}`;
  }
  navigate({ search: new_search });
};

export interface IhandleSearchCode {
  search: string;
  searchCode: string;
  navigate: any;
}

export const handleSearchCode = ({
  search,
  searchCode,
  navigate,
}: IhandleSearchCode) => {
  const location = document.location.search;
  const query = new URLSearchParams(location);
  const prevCode = query.get("code");
  let new_search = search;

  if (prevCode || prevCode?.length === 0) {
    new_search = new_search.replace(
      `code=${prevCode.split(" ").join("%20")}`,
      `code=${searchCode}`
    );
  } else if (prevCode === null) {
    console.log("masuk");
    new_search = `${new_search}&&code=${searchCode}`;
  }
  navigate({ search: new_search });
};
