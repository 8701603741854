import _ from "lodash";
import AxiosInstance from "../../../config/AxiosInstance";
import { IMeta, IPagination, ISort } from "../../helpers/helper";
import {
  ERetailShopSortField,
  IRetailShop,
  PRetailShopFilter,
} from "../entities";
import IRetailShopRepository from "../repository";

export default class RetailShopRepository implements IRetailShopRepository {
  //   private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_SHOP_ROUTE = "/admin/retail-shop";
  private REGISTER_RETAIL_SHOP_ROUTE = "/admin/auth/signup";

  //   constructor(apiClientFactory: ApiClientFactory) {
  //     this.apiClientFactory = apiClientFactory;
  //   }
  //   private retailShopRepo: IRetailShopRepository;

  //   constructor(retailShopRepo: IRetailShopRepository) {
  //     this.retailShopRepo = retailShopRepo;
  //   }

  buildFilter(filter: PRetailShopFilter) {
    const created_at = filter.created_at_enabled
      ? `${filter.created_at_start},${filter.created_at_end}`
      : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.created_at_enabled;
    delete clonedFilter.created_at_start;
    delete clonedFilter.created_at_end;

    return {
      ...clonedFilter,
      created_at,
    };
  }

  buildSort(sort: ISort<ERetailShopSortField>[]) {
    const generateSort = `${sort[0].field} ${sort[0].dir}`;
    return generateSort;
  }

  async getRetailShop(
    pagination: IPagination,
    filter: PRetailShopFilter,
    sorts: ISort<ERetailShopSortField>[]
  ): Promise<[IRetailShop[], IMeta]> {
    try {
      const params = _.pickBy(
        {
          ...pagination,
          ...this.buildFilter(filter),
        },
        (val) => val
      );

      const sort = this.buildSort(sorts);

      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_SHOP_ROUTE}?sort=${sort}`,
        {
          params,
        }
      );
      const { data, meta } = response.data;
      return [data, meta];
    } catch (error) {
      throw error;
    }
  }
}
