import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Alogout: FC<IIconProps> = ({
  stroke = "#666666",
  width = "25",
  height = "25",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0557 8.48132L28.3617 14.7873L22.0557 21.0934"
        stroke={stroke}
        strokeWidth="2.4023"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5458 14.7872H28.3618"
        stroke={stroke}
        strokeWidth="2.4023"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5453 28H3.1373C2.81874 28 2.51322 27.8734 2.28797 27.6481C2.06271 27.4229 1.93616 27.1174 1.93616 26.7988V2.77585C1.93616 2.45729 2.06271 2.15177 2.28797 1.92652C2.51322 1.70126 2.81874 1.57471 3.1373 1.57471H11.5453"
        stroke={stroke}
        strokeWidth="2.4023"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
