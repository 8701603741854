import { useLocation, Navigate } from "react-router-dom";
import useAuth from "./useAuth";
import Layout from "../components/organisms/Layout";

interface IRequireAuth {
  allowedRoles: string[];
}

const RequireAuth: React.FC<IRequireAuth> = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const {role} = auth
  const location = useLocation();

  return allowedRoles?.includes(role) ? (
    <Layout />
  ) : auth?.role ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
