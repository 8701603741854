import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { breadcrumbnext } from "../../assets/icons";
import Loading from "../../components/atoms/loading/Loading";
import { useFetchRetailTransactionTrack } from "../../hooks/retail_transaction";
import { formatDate } from "../../helpers/common";

const TransactionProductTrack = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data, isLoading } = useFetchRetailTransactionTrack(
    params?.code || ""
  );

  const goBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <div className="w-full flex justify-center mt-20">
        <Loading />
      </div>
    );
  }

  return (
    <main className="text-textPrimary">
      <div onClick={goBack} className="flex gap-1">
        <img src={breadcrumbnext} alt="-" className="w-2" />
        <span className="text-xs">Back</span>
      </div>
      <hr className="mt-3" />
      <section className="mt-4">
        <h1 className="text-xs">Track Order</h1>
        <hr className="mt-3" />
        {data?.[0].history.map((el, idx) => (
          <article key={idx} className="my-4 font-rom text-xs">
            <div>
              <h1>{el.status}</h1>
            </div>
            <div>
              <h1 className="text-textSecondary">
                {formatDate(el.date_time, "llll")}
              </h1>
            </div>
            <hr className="my-3" />
          </article>
        ))}
      </section>
    </main>
  );
};

export default TransactionProductTrack;
