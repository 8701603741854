import React from "react";
import Select, { SingleValue } from "react-select";

interface IAsortProps {
  sort: SingleValue<{ label: string; value: string }>;
  listSort: any[];
  handleChangeSort: (sort:SingleValue<{ label: string; value: string }>) => void;
}

const Asort: React.FC<IAsortProps> = ({ sort, listSort, handleChangeSort }) => {
  return (
    <Select
      value={sort}
      options={listSort}
      placeholder="Sort by"
      className="font-rom text-xs"
      onChange={handleChangeSort}
    />
  );
};

export default Asort;
