import React, { useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import RetailShop from "../../pages/retail_shop/RetailShop";
import {
  initialRetailShopState,
  RetailShopContextProvider,
} from "../../store/retail_shop/context";
import { RetailShopReducer } from "../../store/retail_shop/reducer";

const RetailShopRoute = () => {
  const [RetailShopState, RetailShopDispatch] = useReducer(
    RetailShopReducer,
    initialRetailShopState
  );

  return (
    <RetailShopContextProvider value={{ RetailShopState, RetailShopDispatch }}>
      <Routes>
        <Route path="/" element={<RetailShop />} />
        {/* <Route path="/:id" element={<RetailShopDetail />} />, */},
      </Routes>
    </RetailShopContextProvider>
  );
};

export default RetailShopRoute;
