import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  aspectRatio: 2,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "sell",
      data: [100, 200, 150, 300, 400, 230, 400, 640],
      borderColor: "#ed4846",
      backgroundColor: "#ed4846",
    },
  ],
};

const MCharts = () => {
  return (
    <div className="bg-white rounded-lg shadow-md p-7">
      <aside className="w-full flex justify-between">
        <h1>Store Traffic</h1>
        <h1>2022</h1>
      </aside>
      <aside className="mt-5">
        <Line options={options} data={data} />
      </aside>
    </div>
  );
};

export default MCharts;
