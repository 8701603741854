import React, { useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import TransactionProduct from "../../pages/transaction_product/TransactionProduct";
import {
  initialTransactionProductState,
  TransactionProductContextProvider,
} from "../../store/transaction_product/context";
import { TransactionProductReducer } from "../../store/transaction_product/reducer";
import TransactionProductDetail from "../../pages/transaction_product/TransactionProductDetail";
import TransactionProductTrack from "../../pages/transaction_product/TransactionProductTrack";
import TransactionProductMobile from "../../pages/transaction_product/TransactionProductMobile";

const TransactionProductRoute = () => {
  const [TransactionProductState, TransactionProductDispatch] = useReducer(
    TransactionProductReducer,
    initialTransactionProductState
  );
  return (
    <TransactionProductContextProvider
      value={{ TransactionProductState, TransactionProductDispatch }}
    >
      <Routes>
        <Route path="/" element={<TransactionProduct />} />
        <Route path="/:code" element={<TransactionProductDetail />} />
        <Route path="/track/:code" element={<TransactionProductTrack />} />
        <Route path="/detail/:code" element={<TransactionProductMobile />} />
      </Routes>
    </TransactionProductContextProvider>
  );
};

export default TransactionProductRoute;
