// import { IRetailBank } from "../retailProfile/entities";

export interface IRetailShop {
  description: string;
  id: number;
  is_banned: boolean;
  is_closed: boolean;
  logo_path: string;
  name: string;
  retail_address: IRetailAddress;
  //   retail_bank: IRetailBank;
  retail_wallet: IRetailWallet;
  retail_user_id: number;
}
export type PRetailShop = Partial<IRetailShop>;

export interface IRetailAddress {
  address: string;
  address_city_id: number;
  address_city_name: string;
  address_province_id: number;
  address_province_name: string;
  address_subdistrict_id: number;
  address_subdistrict_name: string;
  country: string;
  id: number;
  is_used_for_transaction: boolean;
  retail_shop_id: number;
  zip_code: string;
}
export type PRetailAddress = Partial<IRetailAddress>;

export interface IRetailWallet {
  id: number;
  amount: number;
  retail_shop_id: number;
}
export type PRetailWallet = Partial<IRetailWallet>;

interface IRetailShopFilter {
  id: string;
  name: string;
  is_closed: string;
  created_at_enabled: boolean;
  created_at_start: string;
  created_at_end: string;
}
export type PRetailShopFilter = Partial<IRetailShopFilter>;

export enum ERetailShopSortField {
  id = "id",
  name = "name",
  is_banned = "is_banned",
  is_closed = "is_closed",
}

export enum ESort {
  asc = "asc",
  desc = "desc",
}

export const RetailShopSortField = [
  {
    value: ERetailShopSortField.id,
    name: "Id",
  },
  {
    value: ERetailShopSortField.name,
    name: "Name",
  },
];
