import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/atoms/loading/Loading";
import MproductListCard from "../../components/molecules/MproductListCard";
import { useFetchRetailRevenue } from "../../hooks/retail_revenue";
import { formatDate, formatRupiah } from "../../helpers/common";

const RevenueDetail = () => {
  const params = useParams();
  const { data, isLoading } = useFetchRetailRevenue(params?.code || "");

  return (
    <>
      {isLoading ? (
        <div className="w-full flex justify-center mt-20">
          <Loading />
        </div>
      ) : data ? (
        <main className="relative inset-0 font-rom text-xs text-textPrimary">
          <div>
            <h1>{data?.retail_transaction_code}</h1>
          </div>
          <hr className="mt-3" />
          <section className="flex flex-col gap-2 mt-3">
            <div className="flex justify-between">
              <h1 className="text-textSecondary">Shipping (System)</h1>
              <h1>{formatRupiah(data?.buyer_shipping_cost)}</h1>
            </div>
            <div className="flex justify-between">
              <h1 className="text-textSecondary">Transaction Fee</h1>
              <h1>{data?.admin_fee_percentage * 10} %</h1>
            </div>
            <div className="flex justify-between">
              <h1 className="text-textSecondary">Profit</h1>
              <h1>{formatRupiah(data?.admin_fee)}</h1>
            </div>
            <hr />
            <div className="flex justify-between mt-1.5">
              <h1 className="text-textSecondary">Net Profit</h1>
              <h1>
                {formatRupiah(data?.admin_fee - data?.buyer_shipping_cost)}
              </h1>
            </div>
          </section>
          <hr className="mt-4" />
          <section className="mt-4 flex">
            <div className="w-2/3">
              <MproductListCard
                image={data.retail_product_display_image_path}
                name={data.retail_product_name}
                variant={data.retail_product_stock_label}
                quantity={data.quantity}
              />
            </div>
            <div className="w-1/3 flex justify-end items-center">
              <p className="mt-1.5">{formatRupiah(data.price)}</p>
            </div>
          </section>
          <hr className="mt-4" />
          <section className="flex flex-col gap-2 mt-4">
            <div className="flex justify-between">
              <h1 className="text-textSecondary">Transaction Date</h1>
              <h1>{formatDate(data.created_at, "llll")}</h1>
            </div>
            <div className="flex justify-between">
              <h1 className="text-textSecondary">Customer</h1>
              <h1>{data.buyer_username}</h1>
            </div>
            <div className="flex justify-between">
              <h1 className="text-textSecondary">Admin Fee </h1>
              <h1>{formatRupiah(data.admin_fee)}</h1>
            </div>
            <div className="flex justify-between">
              <h1 className="text-textSecondary">Courier</h1>
              <div className="flex flex-col items-end"> 
                <h1>Jnt - Reguler</h1>
                <h1 className="text-textSecondary mt-0.5">({formatRupiah(data.buyer_shipping_cost)})</h1>
              </div>
            </div>
            <div className="flex justify-between">
              <h1 className="text-textSecondary">Total Price</h1>
              <h1>{formatRupiah(data.price)}</h1>
            </div>
            <hr className="mt-2" />
            <div className="flex justify-between mt-2">
              <h1 className="text-textSecondary">Total Payout</h1>
              <h1>{formatRupiah(data.seller_payout)}</h1>
            </div>
          </section>
        </main>
      ) : (
        "-"
      )}
    </>
  );
};

export default RevenueDetail;
