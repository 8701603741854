import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { RetailShopService } from "../../apis/retail_shop";
import { ERetailShopSortField } from "../../core/retail_shop/entities";
import { useQueries } from "../../helpers/QuerySearchParams";

const useFetchRetailShops = () => {
  const query = useQueries(useLocation);
  return useQuery(
    [
      "RetailShop",
      query.get("sort"),
      query.get("dir"),
      query.get("limit"),
      query.get("offset"),
    ],
    () =>
      RetailShopService.getRetailShop(
        {
          offset: Number(query.get("offset")) || 0,
          limit: Number(query.get("limit")) || 10,
        },
        {},
        [
          {
            field: query.get("sort") || ERetailShopSortField.name,
            dir: query.get("dir") || "desc",
          },
        ]
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      staleTime: 20000,
    }
  );
};

const useFetchRetailShop = (id: string) => {
  const query = useQueries(useLocation);
  return useQuery(
    [
      "RetailShop",
      query.get("sort"),
      query.get("dir"),
      query.get("limit"),
      query.get("offset"),
    ],
    () =>
      RetailShopService.getRetailShop(
        {
          offset: Number(query.get("offset")) || 0,
          limit: Number(query.get("limit")) || 10,
        },
        {},
        [
          {
            field: query.get("sort") || ERetailShopSortField.name,
            dir: query.get("dir") || "desc",
          },
        ]
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      staleTime: 20000,
    }
  );
};

export { useFetchRetailShops, useFetchRetailShop };
