import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import moment from "moment";
import LocalStorage from "./config/LocalStorage";
import useAuth from "./helpers/useAuth";
import { storage } from "./utils/Storage";
import CircularLoad from "./components/atoms/loading/CircularLoad";
import Wrapper from "./components/organisms/Wrapper";
import MainRoute from "./routes/Route";

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { setAuth } = useAuth();

  useEffect(() => {
    storage
      .onConnect()
      .then(function () {
        return storage.get("AUTH_TOKEN");
      })
      .then(function (res) {
        if (res) {
          const decoded: { exp: number; role: string; username: string } =
            jwtDecode(res);
          if (decoded.exp * 1000 < moment().valueOf()) {
            storage.del("AUTH_TOKEN");
            LocalStorage.AUTH_TOKEN = "";
            setAuth({ role: "", token: "", username: "" });
          } else {
            LocalStorage.AUTH_TOKEN = res;
            LocalStorage.ROLE = decoded.role;
            setAuth({
              role: decoded.role,
              token: LocalStorage.AUTH_TOKEN,
              username: decoded.username,
            });
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center pt-32">
        <CircularLoad color="#ed4846" height={40} width={40} />
      </div>
    );
  }

  return (
    <Wrapper>
      <MainRoute />
    </Wrapper>
  );
}

export default App;
