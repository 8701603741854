export function useQueries(useLocation: any) {
  return new URLSearchParams(useLocation().search);
}

const setQueryString = (
  filterField: any[],
  newValue: string,
  name: string,
  location: { pathname: string; search: string; hash: string },
  navigate: any
) => {
  const loc = document.location.search;
  const query = new URLSearchParams(loc);
  const { search } = location;
  let new_search;
  for (const key in filterField) {
    let value = query.get(filterField[key]);
    if (value !== null && filterField[key] === name) {
      new_search = search
        .replace(
          `${filterField[key]}=${value}`,
          `${filterField[key]}=${newValue}`
        )
        .replace(`offset=${query.get("offset")}`, "offset=0");
    } else if (filterField[key] === name) {
      let search_offset = search.replace(
        `offset=${query.get("offset")}`,
        "offset=0"
      );
      new_search = `${search_offset}&&${filterField[key]}=${newValue}`;
    }
  }
  return navigate({ search: new_search });
};

export default setQueryString;
