import { createContext } from "react";
import { ITransactionTicketContext, ITransactionTicketState } from "./state";

export const initialTransactionTicketState: ITransactionTicketState = {
  showFilter: false,
  chip: [],
};

const TransactionTicketContext = createContext<ITransactionTicketContext>({
  TransactionTicketState: initialTransactionTicketState,
  TransactionTicketDispatch: () => undefined,
});

export const TransactionTicketContextProvider = TransactionTicketContext.Provider;
export const TransactionTicketContextConsumer = TransactionTicketContext.Consumer;

export default TransactionTicketContext;
