import React, { Fragment } from "react";
import { useQuery } from "react-query";
import Breadcrumb from "../../components/atoms/breadcrumb/Breadcrumb";
import Table from "../../components/organisms/Table";
import { edit, variant } from "../../assets/icons";
import ReactTooltip from "react-tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/atoms/loading/Loading";
import { TicketRequest } from "../../apis/ticket/TicketRequest";
import { useQueries } from "../../helpers/QuerySearchParams";

const breadcrumbRoute = (id?: string) => {
  return [
    {
      id: 1,
      path: "/home",
      name: "Home",
    },
    {
      id: 2,
      path: "/ticket",
      name: "Ticket",
    },
  ];
};

const Ticket = () => {
  const query = useQueries(useLocation);
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    [
      "ticket",
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
    ],
    TicketRequest.getTicket,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Fragment>
      <div className="">
        <Breadcrumb data={breadcrumbRoute()} />
        {isLoading ? (
          <div className="flex justify-center items-center py-20">
            <Loading />
            {/* <div className="container bg-white  shadow rounded-md p-4 w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-primary bg-opacity-70 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-2 bg-primary bg-opacity-70 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-primary bg-opacity-70 rounded col-span-2"></div>
                      <div className="h-2 bg-primary bg-opacity-70 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-primary bg-opacity-70 rounded"></div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        ) : (
          <section className="my-4">
            <Table
              isTable={true}
              data={data?.data}
              tableHead={[
                "Product Image",
                "Product Name",
                "Product Status",
                "Store",
                "Actions",
              ]}
            >
              {data?.data?.data?.map((el: any) => (
                <tr
                  key={el.id}
                  className="border h-24 font-rom hover:bg-gray-50"
                >
                  <td className="border-r flex justify-center py-5">
                    <img
                      src={el.preview_image_path}
                      alt="-"
                      className="w-24 shadow-sm"
                      loading="lazy"
                    />
                  </td>

                  <td className="border-r">{el.name}</td>

                  <td
                    className={
                      "border-r  " +
                      (el.status === "on_selling"
                        ? "text-success"
                        : el.status === "awaiting_approval" ||
                          el.status === "awaiting_revision"
                        ? "text-yellow-400"
                        : "text-primary")
                    }
                  >
                    {el.status.split("_").join(" ")}
                  </td>
                  <td className="border-r">{el.retail_shop_name || "-"}</td>

                  <td className="">
                    <div className="flex justify-evenly">
                      <div>
                        <img
                          data-tip
                          data-for="variant"
                          className="cursor-pointer md:w-5"
                          onClick={() =>
                            navigate(`/ticket/${el.id}/stock-ticket`)
                          }
                          src={variant}
                          alt="variant"
                        />
                        <ReactTooltip id="variant" type="info">
                          <span>Variant</span>
                        </ReactTooltip>
                      </div>
                      <div>
                        <img
                          data-tip
                          data-for="detail"
                          className="cursor-pointer md:w-6"
                          onClick={() => navigate(`/ticket/${el.id}`)}
                          src={edit}
                          alt="detail"
                        />
                        <ReactTooltip id="detail" type="dark">
                          <span>Detail</span>
                        </ReactTooltip>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </Table>
          </section>
        )}
      </div>
    </Fragment>
  );
};

export default Ticket;
