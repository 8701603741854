import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Atruck: FC<IIconProps> = ({
  stroke = "#666666",
  width = "26",
  height = "19",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1429 2.71437H22.7071C22.8779 2.71326 23.045 2.76383 23.1865 2.85944C23.328 2.95505 23.4373 3.09123 23.5 3.25009L25 7.00009"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 9.57129H18.1429"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4286 17.2859C20.8487 17.2859 22 16.1347 22 14.7145C22 13.2943 20.8487 12.1431 19.4286 12.1431C18.0084 12.1431 16.8571 13.2943 16.8571 14.7145C16.8571 16.1347 18.0084 17.2859 19.4286 17.2859Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.57143 17.2859C7.99159 17.2859 9.14286 16.1347 9.14286 14.7145C9.14286 13.2943 7.99159 12.1431 6.57143 12.1431C5.15127 12.1431 4 13.2943 4 14.7145C4 16.1347 5.15127 17.2859 6.57143 17.2859Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M16.8571 14.7144H9.14285"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 14.7143H1.85714C1.62981 14.7143 1.4118 14.624 1.25105 14.4632C1.09031 14.3025 1 14.0845 1 13.8571V1.85714C1 1.62981 1.09031 1.4118 1.25105 1.25105C1.4118 1.09031 1.62981 1 1.85714 1H18.1429V12.4857"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1429 7H25V13.8571C25 14.0845 24.9097 14.3025 24.7489 14.4632C24.5882 14.624 24.3702 14.7143 24.1429 14.7143H22"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
