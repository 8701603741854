export interface ToggleProps {
  input: boolean;
  handleClick: () => void;
  disabled?: boolean;
}

const Toggle: React.FC<ToggleProps> = ({ input, handleClick, disabled }) => {
  return (
    <div className="flex justify-between items-center">
      <div
        className={
          "md:w-8 md:h-4 w-5 h-2.5 flex items-center rounded-full p-1 duration-300 ease-in-out " +
          (input ? " bg-primary p-1.5" : "bg-gray-500")
        }
      >
        <div
          className={
            "bg-white md:w-2.5 w-2 md:h-2.5 h-1.5 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer " +
            (input ? "md:translate-x-3 translate-x-1.5" : "")
          }
          onClick={disabled ? () => {} : handleClick}
        ></div>
      </div>
    </div>
  );
};

export default Toggle;
