import { EAllowedRoles } from "../core/auth/entities";

export const isAdmin = (role: string) => {
  return role === EAllowedRoles.ADMIN ? true : false;
};

export const isPartner = (role: string) => {
  return role === EAllowedRoles.PARTNER ? true : false;
};

export const isOfficial = (role: string) => {
  return role === EAllowedRoles.OFFICIAL ? true : false;
};
