import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { RetailRevenueService } from "../../apis/retail_revenue";
import { ERetailRevenueSortField } from "../../core/retail_revenue/entities";
import { useQueries } from "../../helpers/QuerySearchParams";

const useFetchRetailRevenues = (date: {
  startDate: string;
  endDate: string;
}) => {
  const query = useQueries(useLocation);
  return useQuery(
    [
      "RetailRevenues",
      query.get("sort"),
      query.get("status"),
      query.get("code"),
      query.get("limit"),
      query.get("offset"),
      query.get("date"),
    ],
    () =>
      RetailRevenueService.getRetailRevenues(
        {
          offset: Number(query.get("offset")) || 0,
          limit: Number(query.get("limit")) || 10,
        },
        {
          code: query.get("code") || undefined,
          date: query.get("date") || `${date.startDate},${date.endDate}`,
        },
        [
          {
            field: query.get("sort") || ERetailRevenueSortField.newest,
          },
        ]
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      staleTime: 20000,
    }
  );
};

const useFetchRetailRevenue = (code: string) => {
  return useQuery(
    [`RetailRevenue-${code}`],
    () => RetailRevenueService.getRetailRevenue(code),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchRetailRevenues, useFetchRetailRevenue };
