import { createContext } from "react";
import { IRetailShopContext, IRetailShopState } from "./state";

export const initialRetailShopState: IRetailShopState = {
  showFilter: false,
  chip: [],
};

const RetailShopContext = createContext<IRetailShopContext>({
  RetailShopState: initialRetailShopState,
  RetailShopDispatch: () => undefined,
});

export const RetailShopContextProvider = RetailShopContext.Provider;
export const RetailShopContextConsumer = RetailShopContext.Consumer;

export default RetailShopContext;
