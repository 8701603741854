import { ActionType, RetailShopAction } from "./action";
import { IRetailShopState } from "./state";

export const RetailShopReducer = (
  state: IRetailShopState,
  action: RetailShopAction
) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
  }
};
