import React, { useContext } from "react";
import SidebarContext from "../../store/sidebar/context";
import MChart from "../../components/molecules/MChart";
import { logo } from "../../assets/icons";

interface IHomeCardProps {
  title: string;
  subtitle: string;
  destination: string;
}

const HomeCard: React.FC<IHomeCardProps> = ({
  title,
  subtitle,
  destination,
}) => {
  return (
    <article className="bg-white shadow-md rounded-lg border md:h-48 h-40 max-h-52 border-stroke text-textPrimary">
      <div className="p-5 h-2/3">
        <div className="flex justify-between font-rom">
          <h1>{title}</h1>
          <h1>i</h1>
        </div>
        <div className="mt-1.5">
          <h1 className="text-2xl">{subtitle}</h1>
        </div>
      </div>
      <div className="border-t-2 border-stroke px-5 flex items-center h-1/3 w-full">
        <h1 className="text-base truncate cursor-pointer font-rom hover:font-med hover:text-primary">
          Go to Transaction
        </h1>
      </div>
    </article>
  );
};

interface ITrendingCardProps {
  index: number;
  title: string;
  subtitle: string;
}
const TrendingCard: React.FC<ITrendingCardProps> = ({
  index,
  subtitle,
  title,
}) => {
  return (
    <aside>
      <div className={"flex items-center " + (index !== 5 ? "my-5" : "mt-5")}>
        <div className="w-1/12 flex justify-center">
          <h1 className="text-primary text-4xl">{index}</h1>
        </div>
        <div className="w-11/12 flex items-start">
          <div className="w-12 ml-2">
            <img
              src={logo}
              alt="-"
              className="w-full rounded-md aspect-square"
            />
          </div>
          <div className="ml-4 truncate">
            <h1 className="truncate">{title}</h1>
            <h1 className="truncate text-textPrimary font-rom">{subtitle}</h1>
          </div>
        </div>
      </div>
      {index !== 5 && <hr />}
    </aside>
  );
};

const dummyTrending = Array(5).fill({
  title: "Black T-shirt for Man",
  subtitle: "viewer: 2003",
});

const Home = () => {
  const { SidebarState } = useContext(SidebarContext);
  const { showSidebar } = SidebarState;
  return (
    <main className="w-full h-full">
      <section>
        <aside>
          <h1 className="font-Bold text-lg">Important Today's</h1>
          <span className="text-textPrimary">
            Activities you need to monitor to maintain buyer satisfaction
          </span>
        </aside>

        <aside className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 md:gap-5 gap-4 mt-8">
          <HomeCard title="New Order" subtitle="200" destination="" />
          <HomeCard title="Complain Order" subtitle="200" destination="" />
          <HomeCard title="Total Product Sold" subtitle="200" destination="" />
          <HomeCard title="Total Ticket Sold" subtitle="200" destination="" />
        </aside>
      </section>

      <section className="mt-10">
        <aside>
          <h1 className="font-Bold text-lg">Analyze your store and products</h1>
          <span className="text-textPrimary">
            You can see your Store Traffic and your Most Trending Product
          </span>
        </aside>

        <aside className="grid grid-cols-1 md:grid-cols-2 md:gap-5 gap-4 mt-8">
          <article>
            <MChart />
          </article>
          <article>
            <div
              className={
                "bg-white rounded-lg shadow-md p-7 md:mt-0 mt-4 w-auto duration-500"
                // (showSidebar
                //   ? "3xl:h-[41.5vh] 2xl:h-[44.5vh] xl:h-[35vh] h-[33vh]"
                //   : "3xl:h-[47vh] 2xl:h-[50vh] xl:h-[40vh] h-[35vh]")
              }
            >
              <aside className="w-full flex justify-between">
                <h1>Trending Products</h1>
                <h1>2022</h1>
              </aside>

              <aside className="no-scrollbar mt-1 overflow-auto h-auto w-full">
                {dummyTrending.map((el: any, idx: number) => (
                  <TrendingCard
                    key={idx}
                    index={idx + 1}
                    title={el.title}
                    subtitle={el.subtitle}
                  />
                ))}
              </aside>
            </div>
          </article>
        </aside>
      </section>
    </main>
  );
};

export default Home;
