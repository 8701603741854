import React from "react";
import { zeroPad } from "react-countdown";

interface IAcountdownProps {
  seconds: number;
  minutes: number;
  hours: number;
  days:number;
  completed: boolean;
}

const Acountdown: React.FC<IAcountdownProps> = ({ completed,days, hours, minutes, seconds}) => {
  if (completed) {
    return <h1 className="sm:text-sm text-xxs font-rom">Expired</h1>;
  } else {
    return (
      <div className="sm:text-sm text-xxs font-rom flex items-center">
       <p className="text-primary">{`${zeroPad(days)[0]}${zeroPad(days)[1]}`}</p>
       <p className="mx-0.5">:</p>
       <p className="text-primary">{`${zeroPad(hours)[0]}${zeroPad(hours)[1]}`}</p>
       <p className="mx-0.5">:</p>
       <p className="text-primary">{`${zeroPad(minutes)[0]}${zeroPad(minutes)[1]}`}</p>
       <p className="mx-0.5">:</p>
       <p className="text-primary">{`${zeroPad(seconds)[0]}${zeroPad(seconds)[1]}`}</p>
      </div>
    );
  }
};

export default Acountdown;
