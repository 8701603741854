export enum ActionType {
  ShowFilter,
  ShowRejectTicket,
  ShowRevisiTicket,
  HandleChip,
}

export interface ShowFilter {
  type: ActionType.ShowFilter;
}

export interface ShowRejectTicket {
  type: ActionType.ShowRejectTicket;
  payload: { status: boolean; id: number };
}

export interface ShowRevisiTicket {
  type: ActionType.ShowRevisiTicket;
  payload: { status: boolean; id: number };
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export type TicketAction =
  | ShowFilter
  | HandleChip
  | ShowRejectTicket
  | ShowRevisiTicket;
