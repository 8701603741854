import React from "react";
import Select, { SingleValue } from "react-select";
import AsearchInput from "../atoms/AsearchInput";
import Asort from "../atoms/Asort";

interface IMfilterGeneral {
  sort: SingleValue<{ label: string; value: string }>;
  listSort: any[];
  search: string;
  setSearch: (value: string) => void;
  handleSearch: () => void;
  handleChangeSort: (
    sort: SingleValue<{ label: string; value: string }>
  ) => void;
}

const MfilterGeneral: React.FC<IMfilterGeneral> = ({
  sort,
  listSort,
  search,
  setSearch,
  handleSearch,
  handleChangeSort,
}) => {

  return (
    <aside className="flex justify-between sm:px-4 px-0 sm:py-4 py-1.5 sm:gap-5">
      <article className="sm:w-[55%] w-full flex sm:gap-5">
        <div className="sm:w-3/5 w-full flex items-center">
          <AsearchInput
            search={search}
            setSearch={setSearch}
            handleSearch={handleSearch}
          />
        </div>
        <div className="sm:w-2/5 sm:inline-flex hidden">
          <Select className="font-rom text-xs w-full"  />
        </div>
      </article>
      <article className="w-[45%] sm:flex hidden justify-end">
        <div className="w-1/3">
          <Asort
            sort={sort}
            listSort={listSort}
            handleChangeSort={handleChangeSort}
          />
        </div>
      </article>
    </aside>
  );
};

export default MfilterGeneral;
