import AxiosInstance from "../../config/AxiosInstance";

export class AuthService {
  private static AUTH_SIGNIN_ROUTE = "/admin/auth/signin";

  static login = async (payload: { username: string; password: string }) => {
    try {
      const response = await AxiosInstance.Celestine().post(
        `${this.AUTH_SIGNIN_ROUTE}`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error: any) {
      return error.response.data;
    }
  };
}
