import { FC, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

interface IWrapperProps {
    children: JSX.Element;
  }
  
  const Wrapper: FC<IWrapperProps> = ({ children }) => {
    const { pathname, search } = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [pathname, search]);
    return children;
  };

  export default Wrapper