export enum ERetailTransactionTicketStatus {
  WaitingPayment = "awaiting_payment",
  Success = "success",
  Expired = "expired",
  Failed = "failed",
  Canceled = "canceled",
}

export interface IRetailTransactionTicketCounter {
  status: string;
  count: number;
}
export type PRetailTransactionCounter = Partial<IRetailTransactionTicketCounter>;

export interface IRetailTransactionTicket {
  bank: string;
  code: string;
  created_at: string;
  done: boolean;
  email: string;
  expired_at: string;
  extra_fee: {
    name: string;
    type: string;
    scope: string;
    amount: number;
  }[];
  fullname: string;
  id: number;
  image_url: string;
  installment: string;
  payment_channel: string;
  payment_method: string;
  price: number;
  quantity: number;
  retail_revenue_id: number;
  retail_shop_id: number;
  retail_shop_name: string;
  retail_shop_logo: string;
  retail_wallet_mutation_attempt: number;
  retail_wallet_mutation_id: number;
  status: string;
  sub_title: string;
  ticket_bundle_id: number;
  ticket_id: number;
  ticket_usage_slot: number;
  title: string;
  total_price: number;
  updated_at: string;
  usage_limit_per_user: number;
  usage_limit_per_user_type: string;
  used_count: number;
  user_id: number;
  username: string;
  valid_time_from: string;
  valid_time_indefinite: true;
  valid_time_until: string;
  wallet_mutation_id: number;
}

export type PRetailTransactionTicket = Partial<IRetailTransactionTicket>;

interface IRetailTransactionTicketFilter {
  status: string;
  code: string;
  retail_shop_id: string;
  date: string;
}
export type PRetailTransactionTicketFilter =
  Partial<IRetailTransactionTicketFilter>;

export enum ERetailTransactionTicketSortField {
  newest = "-created_at",
  oldest = "created_at",
  mostQuantity = "-quantity",
  leastQuantity = "quantity",
}

export const listFilter = [
  {
    title: "All transaction",
    status: [
      ERetailTransactionTicketStatus.WaitingPayment,
      ERetailTransactionTicketStatus.Success,
      ERetailTransactionTicketStatus.Canceled,
      ERetailTransactionTicketStatus.Expired,
      ERetailTransactionTicketStatus.Failed,
    ],
  },
  {
    title: "Waiting Payment",
    status: [ERetailTransactionTicketStatus.WaitingPayment],
  },
  {
    title: "Success",
    status: [ERetailTransactionTicketStatus.Success],
  },

  {
    title: "Cancelled",
    status: [
      ERetailTransactionTicketStatus.Canceled,
      ERetailTransactionTicketStatus.Failed,
      ERetailTransactionTicketStatus.Expired,
    ],
  },
];

export const listSort = [
  { label: "Newest Transaction", value: `${ERetailTransactionTicketSortField.newest}` },
  { label: "Oldest Transaction", value: `${ERetailTransactionTicketSortField.oldest}` },
  {
    label: "Most Quantity",
    value: `${ERetailTransactionTicketSortField.mostQuantity}`,
  },
  {
    label: "Least Quantity",
    value: `${ERetailTransactionTicketSortField.leastQuantity}`,
  },
];
