import React from "react";
import Pagination from "../atoms/pagination/Pagination";

interface TableProps {
  tableHead: any[];
  isTable: boolean;
  data: { data: any; meta: any };
}

const Table: React.FC<TableProps> = ({
  tableHead,
  children,
  data,
  isTable,
}) => {
  return (
    <div>
      <table className="w-full border relative text-center text-sm">
        <thead>
          <tr className="h-18 sticky top-0 bg-white shadow-sm">
            {tableHead.map((el, idx) => (
              <th key={idx} className="border-r h-18">
                <div className="flex justify-center items-center">
                  <h1>{el}</h1>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
      <Pagination
        length={data?.data?.length}
        total={data?.meta?.total}
        isTable={isTable}
      />
    </div>
  );
};

export default Table;
