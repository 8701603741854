import React from "react";

const primary = "bg-primary text-white hover:bg-opacity-70";
const secondary =
  "bg-white text-black border border-stroke hover:bg-opacity-70 hover:text-textPrimary";
const disabled = "bg-stroke text-black cursor-auto";
const long = "w-44 p-2";
const normal = "w-28 py-2.5 px-3";
const full = "w-full h-full p-2.5";

const buttonStyle = {
  primary,
  secondary,
  disabled,
};

const buttonModel = {
  long,
  normal,
  full,
};

interface IButtonProps {
  title: string;
  onClick?: () => void;
  theme?: "primary" | "secondary" | "disabled";
  model?: "long" | "normal" | "full";
  type?: "button" | "submit" | "reset";
}

const Abutton: React.FC<IButtonProps> = ({
  title,
  onClick,
  theme = "primary",
  model = "normal",
  type = "button",
}) => {
  return (
    <button
      onClick={onClick}
      disabled={theme === "disabled" && true}
      type={type}
      className={
        "rounded-md cursor-pointer shadow-sm " +
        buttonStyle[theme] +
        " " +
        buttonModel[model]
      }
    >
      <h1 className="text-xs truncate">{title}</h1>
    </button>
  );
};

export default Abutton;
