import React from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { IRetailRevenue } from "../../core/retail_revenue/entities";
import { formatRupiah, isMobile } from "../../helpers/common";
import MproductListCard from "./MproductListCard";

export const renderStatusColor = (status: number) => {
  const statusColor: any = {
    0: "text-success",
    1: "text-success",
    2: "text-success",
    3: "text-warning",
    4: "text-primary",
    5: "text-primary",
    6: "text-primary",
    7: "text-success",
  };
  return statusColor[status];
};

interface IMRevenueCardProps {
  data: IRetailRevenue;
}

const MRevenueCard: React.FC<IMRevenueCardProps> = ({ data }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const [more, setMore] = useState(false);
  // const length = 1;

  const goDetail = (code: string) => {
    navigate(`${pathname}/${code}`);
  };

  return (
    <aside
      onClick={
        isMobile() ? () => goDetail(data.retail_transaction_code) : () => {}
      }
      className="w-full bg-white rounded-lg shadow-md"
    >
      <article className="sm:px-5 sm:pt-5 sm:pb-5 px-3 pt-3 pb-0 flex justify-between sm:border-b-2 sm:text-sm text-xxs">
        <div className="sm:flex hidden xl:flex-row flex-col gap-1 w-2/3">
          <span className="text-primary">{data.retail_transaction_code}</span>
          <span>| {data.buyer_username} |</span>
          <span className="text-textSecondary">
            Transaction Date : {moment(data.created_at).format("llll")}
          </span>
        </div>

        <div className="sm:w-auto w-full gap-1">
          <div className="sm:flex justify-between items-center">
            <span className="sm:inline hidden">
              Admin Fee :{" "}
              <span className="text-primary">
                {data.admin_fee_percentage * 100}%
              </span>
            </span>
            <div className="sm:hidden inline">
              <h1 className="font-rom text-textSecondary">
                {moment(data.created_at).format("llll")}
              </h1>
              <h1 className="text-black mt-1">
                {data.retail_transaction_code}
              </h1>{" "}
            </div>
          </div>
          <hr className="sm:hidden mt-2" />
        </div>
      </article>

      <article className="sm:px-5 px-3 sm:grid hidden">
        <aside className="flex justify-between items-center">
          <div className="flex gap-x-3 items-center my-2.5 text-xs">
            <img src={data?.retail_shop_logo} alt="-" className="sm:w-7 w-6" />
            <h1 className="whitespace-nowrap sm:text-sm text-xxs font-rom">
              {data?.retail_shop_name || "-"}
            </h1>
            <hr />
          </div>
        </aside>
        <hr />
        <aside>
          <div className="sm:hidden">
            <span className="sm:hidden inline mt-0.5 text-xxs font-rom">
              username: {data.buyer_username}
            </span>
          </div>
        </aside>
        <hr className="sm:hidden mt-1.5" />
      </article>

      <article className="sm:p-5 p-3 text-textPrimary sm:grid hidden">
        <aside className="grid sm:grid-cols-3 grid-cols-1 gap-7">
          <div className="col-span-2">
            <div className="sm:border-r-2 sm:pr-5 mb-3 grid grid-cols-4 gap-5">
              <div className="col-span-2 border-r">
                <MproductListCard
                  image={data.retail_product_display_image_path}
                  name={data.retail_product_name}
                  variant={data.retail_product_stock_label}
                  quantity={data.quantity}
                />
              </div>
              <div className="text-xs font-rom border-r">
                <p className="font-med">
                  Price <span className="font-rom">(pcs)</span>{" "}
                </p>
                <p className="mt-1.5">{formatRupiah(data.price)}</p>
              </div>
              <div className="text-xs font-rom">
                <p className="font-med">
                  Price <span className="font-rom">(Total)</span>{" "}
                </p>
                <p className="mt-1.5">{formatRupiah(data.total)}</p>
              </div>
            </div>
            <hr className="mt-5" />
            <div className="text-xs w-full flex gap-3 pt-3">
              <div className="flex justify-between w-1/2 pr-3 border-r">
                <h1>Total Price</h1>
                <h1 className="text-textSecondary">
                  {formatRupiah(data.total)}
                </h1>
              </div>
              <div className="flex justify-between w-1/2">
                <h1>Total Payout</h1>
                <h1 className="text-textSecondary">
                  {formatRupiah(data.seller_payout)}
                </h1>
              </div>
            </div>
          </div>
          <div className="text-xs font-rom flex flex-col gap-2">
            <div className="flex justify-between">
              <h1 className="font-med">
                Shipping <span className="font-rom">(System)</span>
              </h1>
              <h1>{formatRupiah(data.buyer_shipping_cost)}</h1>
            </div>
            {/* <div className="flex justify-between">
              <h1 className="font-med">Transaction Fee</h1>
              <h1>{formatRupiah(data.admin_fee)}</h1>
            </div> */}
            <div className="flex justify-between">
              <h1 className="font-med">Profit</h1>
              <h1>{formatRupiah(data.admin_fee)}</h1>
            </div>
            <hr className="mt-5" />
            <div className="flex justify-between pt-1">
              <h1 className="font-med">Net Profit</h1>
              <h1 className="text-primary font-med">
                {formatRupiah(data.admin_fee - data.buyer_shipping_cost)}
              </h1>
            </div>
          </div>
        </aside>
      </article>

      <article className="blok sm:hidden text-xs p-4 flex gap-4">
        <div className="w-1/2 border-r">
          <h1 className="text-textSecondary font-rom">Profit</h1>
          <h1 className="mt-1.5">{formatRupiah(data.admin_fee)}</h1>
        </div>
        <div>
          <h1 className="text-textSecondary font-rom">Net Profit</h1>
          <h1 className="mt-1.5 text-primary">
            {formatRupiah(data.admin_fee - data.buyer_shipping_cost)}
          </h1>
        </div>
      </article>
    </aside>
  );
};

export default MRevenueCard;
