import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

interface ICircularLoadProps {
  color?: string;
  height?: number;
  width?: number;
}

const CircularLoad: React.FC<ICircularLoadProps> = ({
  color = "#ed4846",
  height = 20,
  width = 20,
}) => {
  return <Loader type="TailSpin" color={color} height={height} width={width} />;
};

export default CircularLoad;
