import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Aticket: FC<IIconProps> = ({
  stroke = "#666666",
  width = "28",
  height = "21",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.35559V19.3556"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.00002 15.2556C0.998741 15.0253 1.078 14.8018 1.22409 14.6237C1.37018 14.4457 1.5739 14.3243 1.80002 14.2806C2.69941 14.0896 3.50594 13.5956 4.08465 12.8811C4.66336 12.1666 4.97913 11.275 4.97913 10.3556C4.97913 9.43614 4.66336 8.54458 4.08465 7.8301C3.50594 7.11562 2.69941 6.62156 1.80002 6.43059C1.5739 6.38687 1.37018 6.26548 1.22409 6.08744C1.078 5.9094 0.998741 5.68589 1.00002 5.45559V2.35559C1.00002 2.09037 1.10537 1.83602 1.29291 1.64848C1.48044 1.46095 1.7348 1.35559 2.00002 1.35559H26C26.2652 1.35559 26.5196 1.46095 26.7071 1.64848C26.8947 1.83602 27 2.09037 27 2.35559V5.45559C27.0013 5.68589 26.922 5.9094 26.7759 6.08744C26.6299 6.26548 26.4261 6.38687 26.2 6.43059C25.3006 6.62156 24.4941 7.11562 23.9154 7.8301C23.3367 8.54458 23.0209 9.43614 23.0209 10.3556C23.0209 11.275 23.3367 12.1666 23.9154 12.8811C24.4941 13.5956 25.3006 14.0896 26.2 14.2806C26.4261 14.3243 26.6299 14.4457 26.7759 14.6237C26.922 14.8018 27.0013 15.0253 27 15.2556V18.3556C27 18.6208 26.8947 18.8752 26.7071 19.0627C26.5196 19.2502 26.2652 19.3556 26 19.3556H2.00002C1.7348 19.3556 1.48044 19.2502 1.29291 19.0627C1.10537 18.8752 1.00002 18.6208 1.00002 18.3556V15.2556Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
